import { 
  ChartDataShape,
  LinearXAxis,
  LinearXAxisTickSeries,
  LinearYAxis,
  LinearYAxisTickSeries,
  LineChart,
  LineSeries,
} from 'reaviz';
import { atoms, themeColors } from 'misc'
import { useDataLadQuery, useDataQuery } from 'generated';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { interpolateMagma as color } from 'd3-scale-chromatic'
type Data = {
  key: number|string,
  data: number|Data
}[]|undefined

export default function BarChart ({noText=false, label=false}) {
  const sic = useRecoilValue(atoms.sic)
  const year = useRecoilValue(atoms.year)
  const chartParam = useRecoilValue(atoms.chartParam)

  const [ data, setData ] = useState<ChartDataShape[]>()
  const { data:source, refetch:getData } = useDataLadQuery()

  useEffect(()=>{
    if(source?.salaries){
      const groups = source.salaries.reduce((agg:any,v)=>{
        if(v?.salary&&v.lad?.name&&v.year){
          if(agg?.[v.lad?.name]){
            agg[v.lad.name]?.push({key:v.year, data: v.salary  })
            return agg
          }else{
            return {...agg, [v.lad.name]: [{key:v.year, data: v.salary  }]}
          }
        }
      },{} as {[key:string]: Data })

      if(groups){
        setData(
          // @ts-ignore
          Object.entries(groups).map(v=>({
            key: v[0],
            data: (v[1] as any).sort((a:any,b:any)=>a.key-b.key)
          }))
        )
      }else{
        console.log('error', groups)
      }
    }
  },[source, setData, chartParam])

  useEffect(()=>{
    if(getData){
      getData({sic: '691' || String(sic===64?692:sic)})
    }
  },[sic, getData])

  return <div style={{minWidth:345}}>
    <LineChart
      width={345}
      height={150}
      gridlines={null}
      xAxis={
        <LinearXAxis
          type="value"
          domain={[2014,2021]}
          axisLine={null}
          tickSeries={
            <LinearXAxisTickSeries 
              line={null} 
              label={null}
            />
          }
        />
      }
      yAxis={
        <LinearYAxis
          type='value'
          axisLine={null}
          domain={(!source)? 
            undefined:
            (chartParam==='proportion'||chartParam==='count')?
            [
              Number(source.salaries_.agg?.min?.salary),
              Number(source.salaries_.agg?.max?.salary)
            ]:
            [
              Number(source.salaries_.agg?.min?.salary),
              Number(source.salaries_.agg?.max?.salary)
            ]
          }
          tickSeries={
            <LinearYAxisTickSeries 
              line={null}
              label={null}
            />
          }
        />
      }
      data={ data }
      series={
        <LineSeries
          type='grouped'
      colorScheme={(_data, _index, active) => color(0.8-(_index/10)) /*(active ? themeColors[6] : themeColors[4])*/ }
        />
      }
    />
  </div>
}