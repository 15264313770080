import { Layer } from '@urbica/react-map-gl'
import { Expression, Map, StyleFunction } from 'mapbox-gl';
import { atoms, themeColors } from 'misc'
import { useEffect } from 'react';
import { useRecoilState } from 'recoil'
import {useLadDataSalariesLazyQuery, useLadDataSkillsLazyQuery } from 'generated'

export default function LADs ({map}:{map?:Map}) {
  const [ parameter ] = useRecoilState(atoms.parameter);
  const [ year ] = useRecoilState(atoms.year)
  const [ sic ] = useRecoilState(atoms.sic)
  const [ source ] = useRecoilState(atoms.source)
  const [ skill ] = useRecoilState(atoms.skill)

  const [getLadDataSalaries, { data: salaries, refetch: refetchLadDataSalaries }] = useLadDataSalariesLazyQuery()
  const [getLadDataSkills, { data:skills, refetch: refetchLadDataSkills}] = useLadDataSkillsLazyQuery()

  useEffect(()=>{
    if(source==='salaries'){
      if(!refetchLadDataSalaries){
        getLadDataSalaries()
      }else{
        refetchLadDataSalaries({sic: String((source==='salaries'&&sic===64)?692:sic), year})
      }
    }else{
      if(!refetchLadDataSkills){
        getLadDataSkills()
      }else{
        refetchLadDataSkills({sic: String(sic),skill,year})
      }
    }

  },[
    source,
    refetchLadDataSalaries,
    refetchLadDataSkills,
    sic,
    year,
    skill
  ])

  useEffect(()=>{
    if(source==='skills'&&skills?.skill_pricing_by_lad){
      const agg = skills.skill_pricing_by_lad_aggregate.aggregate
      skills.skill_pricing_by_lad.forEach((v,i)=>{
        if(v.lad_code){
          map?.setFeatureState({source:'lad', sourceLayer:'public.lad',id:v.lad_code}, { 
            value: (v[parameter] - agg?.min?.[parameter])/(agg?.max?.[parameter]-agg?.min?.[parameter])
          })
        }
      })
    }else if(source==='salaries'&&salaries?.salary_residuals_by_lad){
      const agg = salaries.salary_residuals_by_lad_aggregate.aggregate
      salaries.salary_residuals_by_lad.forEach((v,i)=>{
        if(v.lad_code){
          map?.setFeatureState({source:'lad', sourceLayer:'public.lad', id:v.lad_code}, { 
            value: (v.salary - agg?.min?.salary)/(agg?.max?.salary - agg?.min?.salary)
          })
        }
      })
    }
  },[salaries,skills,source,map,parameter])

  const colorInt:string | StyleFunction | Expression | undefined = ['case', 
    ['!=', ['feature-state', 'value'], null],
    ['interpolate',
      ['linear'],
      ['feature-state','value'],
      ...themeColors.map((c:any,i:any,a:any)=>[(i/a.length), c]).flat()
    ], 
  'rgba(0,0,0,0.2)']

  return <>

    <Layer 
      id='lad_data'
      source='lad'
      source-layer='public.lad'
      type='fill'
      paint={{
        "fill-color": colorInt,
        "fill-opacity": [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,1,10,0.2
        ],
        "fill-outline-color":'rgba(0,0,0,0.03)'
      }}
      layout={{visibility:'visible'}}
      before='admin-1-boundary-bg'
    />
  </>
}