import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { RecoilRoot } from 'recoil'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import 'assets/css/styles.css'

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: "#ffffff",
    },
  },
  overrides: {
    MuiSelect: {
      root: {
        '&:hover': {
          borderColor: 'black',
        }
      },
      outlined:{
        padding: '0.7rem 0.8rem'
      }
    },
    MuiSwitch:{
      track:{
        backgroundColor:'#ccc'
      }
    },
    MuiOutlinedInput: {
      root: {
        // "& $notchedOutline": {
        //   borderColor: "white"
        // },
        // "&:hover $notchedOutline": {
        //   borderColor: "white"
        // },
        // "&$focused $notchedOutline": {
        //   borderColor: "white"
        // }
      },
      colorSecondary:{
        "& $notchedOutline": {
          borderColor: "white"
        },
        "&:hover $notchedOutline": {
          borderColor: "white"
        },
        "&$focused $notchedOutline": {
          borderColor: "white"
        }
      }
    }
  }
});

const client = new ApolloClient({
  uri: 'https://api.ngs-oxford.org.uk//v1/graphql',
  cache: new InMemoryCache({
    addTypename: false
  })
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <RecoilRoot>
        <Router>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Router>
      </RecoilRoot>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
