import { Typography } from "@material-ui/core"
import { useRecoilState } from "recoil"
import { atoms } from 'misc'
import { StyledSlider } from "./Slider"


function datediff(first:Date, second:Date) {
    //@ts-ignore
    return Math.round((second-first)/(1000*60*60*24));
}

export default function HistorycalSlider (){
  const [ year, setYear] = useRecoilState(atoms.year)
  const [ sic, setSIC] = useRecoilState(atoms.sic)
  
  return <div>
    <div >
      <StyledSlider 
        aria-label="Skills market over time" 
        valueLabelDisplay="off" 
        step={1}
        max={2021}
        min={2014}
        value={year}
        onChange={(e:any,v:number|number[])=>{
          if(typeof(v)==='number'){
            console.log('setind', v)
            setYear(v as any)
          }
        }}
      />
    </div>
    <div style={{
      margin: '-0.8rem 0.5rem',
      display:'flex', 
      justifyContent:'space-between', 
      color: 'black',
      flexBasis:'100%'}}>
      { [2014,2015,2016,2017,2018,2019,2020,2021]
        .map((v, key, arr)=>
          <Typography {...{key}} variant='body2' style={{fontSize:'0.7rem', margin:'1rem -0.5rem'}}>
            {v}
          </Typography>
        )}
    </div>
  </div>
}
