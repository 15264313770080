import Sidebar from './Sidebar'
import Map from './Map'
import ModeToggle from './ModeToggle'

export default function App() {

  return (
    <div className="App" >
      <Map />
      <Sidebar />
      <ModeToggle />
      {/* {info && <Onboarding close={()=>setInfo(false)}/>}
      <InfoButton/> */}
    </div>
  );
}
