import logoLockup from "assets/images/ukri_logo.png"
import oxLogo from "assets/images/ox_logo.png"

export default function Header () {

  return <>
      <div style={{
        position:'absolute',
        paddingLeft:'1.5rem', 
        paddingTop:'1rem', 
        paddingBottom:'1rem',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        backgroundColor:'white',
        width:'calc(100% - 2rem)',
        height:'75px',
        zIndex:100,
        display: 'flex',
        justifyContent:'space-between',
        maxWidth:'380px',
        marginBottom:'1rem'
      }}>
        <img src={logoLockup} style={{objectFit:'cover'}}/>
        <img src={oxLogo} style={{objectFit:'cover', marginRight:'1rem'}}/>
      </div>
      <div style={{
        paddingLeft:'1.5rem', 
        paddingTop:'1rem', 
        paddingBottom:'1rem',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        height:'75px',
        display: 'flex',
        justifyContent:'space-between',
        maxWidth:'380px',
      }}>
        <img src={logoLockup} style={{objectFit:'cover', }} />
        <img src={oxLogo} style={{objectFit:'cover', marginRight:'1rem'}}/>
      </div>
  </>
}