import { useState, useRef, useEffect } from 'react'
import MapGL from '@urbica/react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useRecoilState } from 'recoil'
import { atoms } from 'misc'
import Choropleth from './Choropleth'
import CircularProgress from '@material-ui/core/CircularProgress';

export default ()=>{
  const [viewport, setViewport] = useRecoilState(atoms.viewport)
  const [cursor, setCursor] = useRecoilState(atoms.cursor);
  const [ loaded, setLoaded ] = useState(false)
  return (<>

    <MapGL
      style={{ position:'absolute',top:0,bottom:0,left:0,right:0, border:"none",outline: "none" }}
      mapStyle="mapbox://styles/switch9/ckahu5spr0amr1ik3n1fg0fvt"
      accessToken="pk.eyJ1Ijoic3dpdGNoOSIsImEiOiJjamozeGV2bnkxajV2M3FvNnpod3h4ODlpIn0.CTt2IXV8II6finbTlxEddg"
      onViewportChange={setViewport}
      onLoad={(e:any)=>{
        setLoaded(true)
      }}
      onClick={(e:any)=>{
        console.log(e.target.getStyle())
      }}
      cursorStyle={cursor}
      hash
      viewportChangeMethod="easeTo"
      viewportChangeOptions={{offset:[200, 0]}}
      {...viewport}
    >

      {/* <PointLayer {...{cursor, setCursor}}/> */}
      <Choropleth/>

    </MapGL>
    {!loaded && <div style={{position:'absolute', top:0, bottom:0, left:0, right:0, display:'flex', justifyContent: 'center', alignItems:'center'}}>
      <CircularProgress />
    </div>}
  </>);
}