import { atom } from 'recoil'
import { LSOAData } from 'misc';

export const chartParam = atom({
  key: 'chartParam',
  default: 'price' as 'count'|'proportion'|'price'
})

export const sic = atom({
  key: 'sic',
  default: 691 as 64|691|651 //|692|'professional services'
})


export const layer = atom({
  key:'layer',
  default: 'lad' as 'lad'|'city'
})

export const source = atom({
  key:'source',
  default: 'skills' as 'skills'|'salaries'
})

export const skill = atom({
  key:'skill',
  default: "Commercial Litigation"
})

export const parameter = atom({
  key: 'parameter',
  default: 'price' as 'price'|'count'
})

export const year = atom({
  key: 'year',
  default: 2021 as 2014|2015|2016|2017|2018|2019|2020|2021
})

export const cursor = atom({
  key: 'cursor',
  default: ''
})

export const selected = atom({
  key: 'selected', 
  default: null as LSOAData|null, 
});

export const defaultViewport = { latitude: 53, longitude:-3.5, zoom:5.75 } 

export const viewport = atom({
  key:'viewport',
  default: defaultViewport
})
