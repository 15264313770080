import { RefObject, useCallback, useEffect, useRef, useState } from 'react'

export * from 'misc/recoil'
export * from './types'

export const themeColors = [ '#D9EFF0', '#A6D9DD', '#79C2C7', '#4DB4BB', '#33A9B1', '#00939D', '#007D89', '#00676E', '#00494E', '#003337', '#001617' ].reverse()

export function random(seed:number) {
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

export const toUrlEncoded = (obj:any) => Object.keys(obj).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])).join('&');

export function formatDate(date:Date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}


interface Size {
  width: number
  height: number
}

export function useElementSize<T extends HTMLElement = HTMLDivElement>(
  elementRef: RefObject<T>,
): Size {
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  })

  // Prevent too many rendering using useCallback
  const updateSize = useCallback(() => {
    const node = elementRef?.current
    if (node) {
      setSize({
        width: node.offsetWidth || 0,
        height: node.offsetHeight || 0,
      })
    }
  }, [elementRef])

  // Initial size on mount
  useEffect(() => {
    updateSize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return size
}

export const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: null as null|number, y: null as null|number });

  const updateMousePosition = (ev:any) => {
    setMousePosition({ x: ev.clientX, y: ev.clientY });
  };

  useEffect(() => {
    window.addEventListener("mousemove", updateMousePosition);

    return () => window.removeEventListener("mousemove", updateMousePosition);
  }, []);

  return mousePosition;
};

export function usePrevious(value:any) {
  const ref = useRef<any>();
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  return ref.current;
}
