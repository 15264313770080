import { atoms } from 'misc'
import { useRecoilState } from 'recoil'
import Header from './Header'
import { Route } from 'react-router-dom'
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core'
import HistorycalSlider from './HistorySlider'
import { ArrowDropDown } from '@material-ui/icons'
import LineChartSkill from './LineChartSkill'
import LineChartLad from './LineChartLad'

export default function Dashboard () {
  const [source] = useRecoilState(atoms.source);
  const [sic] = useRecoilState(atoms.sic)
  const [layer] = useRecoilState(atoms.layer);

  return <>
    <div style={{
      width: '400px',
      zIndex: 2,
      position: 'absolute',
      top: '1rem',
      left: '1rem',
      boxShadow: 'inset 0px 4px 0px #ffe964',
      paddingTop: '4px',
    }}>
      <div style={{
        marginBottom:'10px', 
        color: 'black', 
        background: 'white',
        boxShadow: '0px 4px 4px rgb(0 0 0 / 25%), 0px 16px 16px rgb(0 0 0 / 6%)',
        maxHeight: 'calc(100vh - 2.5rem)',
        overflowY: 'scroll'
      }}>
        <Route path='/' exact>
          <>
            <Header />
            <>
              <div style={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                // minHeight:'3.0rem',
                marginTop: '0.5rem'
              }}>
                {/* <Typography style={{marginLeft:'1.5rem'}} variant="h5">
                  {selected? 
                    (selected?.properties.name +
                      ' - ' + selected?.properties.ward + 
                      ' - ' + selected?.properties.postcode||'') :
                    'United Kingdom'}
                </Typography> */}
              </div>
              <div style={{
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                padding: '0.5rem 0',

                display:'flex',
                justifyContent:'start',
                flexDirection:'column',
                alignItems:'start',
                // height:'2.5rem'
              }}>
                <Typography style={{marginLeft:'1.7rem', marginRight:'2rem'}} variant="caption">
                  Current administrative level: {layer==='lad'?'Lower Authority District':'City'}
                </Typography>
                <Typography style={{marginLeft:'1.7rem'}} variant="caption">
                  Current industry filter by SIC: {sic}
                </Typography>
              </div>
            </>
            
            {source==='skills'?
              <div style={{padding:'1.5rem 1.7rem 0 1.7rem', marginTop:'3rem', marginBottom:'-0.5rem'}}>
                <LineChartSkill/>
              </div>:
              <div style={{padding:'1.5rem 1.7rem 0 1.7rem', marginTop:'3rem', marginBottom:'-0.5rem'}}>
                <LineChartLad/>
              </div>
            }
            <div style={{padding:'1.7rem'}}>
              <HistorycalSlider/>
            </div>
            {source==='skills' && 
              <div style={{padding:'0.5rem 1.7rem 1.7rem 1.7rem'}}>
                <ChartParams/>
              </div>}
            <div style={{padding:'0.5rem 1.7rem 1.7rem 1.7rem'}}>
              <SICParams/>
            </div>
            {source==='skills' && <div style={{padding:'0.5rem 1.7rem 1.7rem 1.7rem'}}>
              <MapParams/>
            </div>}
            {source==='skills' && <div style={{padding:'0.5rem 1.7rem 1.7rem 1.7rem'}}>
              <SkillParams/>
            </div>}
          </>
        </Route>
      </div>
    </div>
  </>
}


function ChartParams (){
  const [chartParam, setChartParam] = useRecoilState(atoms.chartParam)

  return  <FormControl variant="outlined" style={{width:'100%'}}>
  <InputLabel id="air-pollutant-types" style={{color:'black'}}>Chart Parameter</InputLabel>
  <Select
    labelId="air-pollutant-types"
    id="air-pollutant-types"
    color={'primary'}
    variant="outlined"
    value={chartParam}
    onChange={(e:any)=>setChartParam(e.target.value)}
    label="Chart Parameter"
    IconComponent={()=><ArrowDropDown style={{color: 'black'}}/>}
    style={{color:'black'}}
  >
    <MenuItem value='price'>Skill pricing</MenuItem>
    <MenuItem value='count'>Skill count</MenuItem>
    <MenuItem value='proportion'>Skill proportion</MenuItem>
  </Select>
</FormControl>
}

function MapParams (){
  const [ param, setParam] = useRecoilState(atoms.parameter)

  return  <FormControl variant="outlined" style={{width:'100%'}}>
  <InputLabel id="air-pollutant-types" style={{color:'black'}}>Mapping Parameter</InputLabel>
  <Select
    labelId="air-pollutant-types"
    id="air-pollutant-types"
    color={'primary'}
    variant="outlined"
    value={param}
    onChange={(e:any)=>setParam(e.target.value)}
    label="Map Parameter"
    IconComponent={()=><ArrowDropDown style={{color: 'black'}}/>}
    style={{color:'black'}}
  >
    <MenuItem value='price'>Skill Price</MenuItem>
    <MenuItem value='count'>Skill Count</MenuItem>
  </Select>
</FormControl>
}

function SICParams (){
  const [ sic, setSIC] = useRecoilState(atoms.sic)

  return  <FormControl variant="outlined" style={{width:'100%'}}>
  <InputLabel id="air-pollutant-types" style={{color:'black'}}>SIC Category</InputLabel>
  <Select
    labelId="air-pollutant-types"
    id="air-pollutant-types"
    color={'primary'}
    variant="outlined"
    value={sic}
    onChange={(e:any)=>isNaN(e.target.value)?setSIC(e.target.value):setSIC(Number(e.target.value) as any)}
    label="SIC Category"
    IconComponent={()=><ArrowDropDown style={{color: 'black'}}/>}
    style={{color:'black'}}
  >
    <MenuItem value='691'>Legal Activities</MenuItem>
    <MenuItem value='64'>Insurance</MenuItem>
    <MenuItem value='651'>Real Estate</MenuItem>

    {/* <MenuItem value='692'>Accounting, Bookkeeping & Auditing</MenuItem>
    <MenuItem value='professional services'>Professional Services</MenuItem> */}
  </Select>
</FormControl>
}

function SkillParams (){
  const [ skill, setSkill] = useRecoilState(atoms.skill)

  return  <FormControl variant="outlined" style={{width:'100%'}}>
  <InputLabel id="air-pollutant-types" style={{color:'black'}}>Skill Selection</InputLabel>
  <Select
    labelId="air-pollutant-types"
    id="air-pollutant-types"
    color={'primary'}
    variant="outlined"
    value={skill}
    onChange={(e:any)=>setSkill(e.target.value)}
    label="SIC Category"
    IconComponent={()=><ArrowDropDown style={{color: 'black'}}/>}
    style={{color:'black'}}
  >
    {["Software Engineering","Python","Commercial Litigation","Contract Preparation","Legal Support","Legal Research"].map(v=>
      <MenuItem value={v}>{v}</MenuItem>
      )}
    {/* <MenuItem value='692'>Accounting, Bookkeeping & Auditing</MenuItem>
    <MenuItem value='professional services'>Professional Services</MenuItem> */}
  </Select>
</FormControl>
}
