import { Source, MapContext } from '@urbica/react-map-gl'
import { Map } from 'mapbox-gl';
import { atoms } from 'misc'
import { useRecoilState } from 'recoil'
import Cities from './Cities';
import LADs from './LADs';

export default function Choropleth () {
  const [ layer ] = useRecoilState(atoms.layer)

  return <>
    <Source
      id='lad'
      type='vector'
      url={'https://tiles.ngs-oxford.org.uk/public.lad.json'}
      promoteId='id'
    />
    <Source
      id='city'
      type='vector'
      url={'https://tiles.ngs-oxford.org.uk/public.city.json'}
      promoteId='id'
    />
    <MapContext.Consumer>
      {(map:Map)=>layer==='lad'?
        <LADs {...{map}}/>:
        <Cities {...{map}}/>
      }
    </MapContext.Consumer>
  </>
}