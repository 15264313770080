import { Layer } from '@urbica/react-map-gl'
import { Expression, Map, StyleFunction } from 'mapbox-gl';
import { atoms, themeColors } from 'misc'
import { useEffect  } from 'react';
import { useRecoilState } from 'recoil'
import {useCityDataSalariesLazyQuery, useCityDataSkillsLazyQuery } from 'generated'

export default function Citiess ({map}:{map?:Map}) {
  const [ parameter ] = useRecoilState(atoms.parameter);
  const [ year ] = useRecoilState(atoms.year)
  const [ sic ] = useRecoilState(atoms.sic)
  const [ source ] = useRecoilState(atoms.source)
  const [ skill ] = useRecoilState(atoms.skill)

  const [getCityDataSalaries, { data: salaries, refetch: refetchCityDataSalaries }] = useCityDataSalariesLazyQuery()
  const [getCityDataSkills, { data:skills, refetch: refetchCityDataSkills}] = useCityDataSkillsLazyQuery()

  useEffect(()=>{
    if(source==='salaries'){
      if(!refetchCityDataSalaries){
        getCityDataSalaries()
      }else{
        refetchCityDataSalaries({sic: String((source==='salaries'&&sic===64)?692:sic), year})
      }
    }else{
      if(!refetchCityDataSkills){
        getCityDataSkills()
      }else{
        refetchCityDataSkills({sic: String((source==='skills'&&sic===64)?692:sic),skill,year})
      }
    }

  },[
    source,
    refetchCityDataSalaries,
    refetchCityDataSkills,
    sic,
    year,
    skill
  ])

  useEffect(()=>{
    if(source==='skills'&&skills?.skill_pricing_by_city){
      const agg = skills.skill_pricing_by_city_aggregate.aggregate
      skills.skill_pricing_by_city.forEach((v,i)=>{
        if(v.city_code){
          map?.setFeatureState({source:'city', sourceLayer:'public.city',id:v.city_code}, { 
            value: (v[parameter] - agg?.min?.[parameter])/(agg?.max?.[parameter]-agg?.min?.[parameter])
          })
        }
      })
    }else if(source==='salaries'&&salaries?.salary_residuals_by_city){
      const agg = salaries.salary_residuals_by_city_aggregate.aggregate
      salaries.salary_residuals_by_city.forEach((v,i)=>{
        if(v.city_code){
          map?.setFeatureState({source:'city', sourceLayer:'public.city', id:v.city_code}, { 
            value: (v.salary - agg?.min?.salary)/(agg?.max?.salary - agg?.min?.salary)
          })
        }
      })
    }
  },[salaries,skills,source,map,parameter])

  const colorInt:string | StyleFunction | Expression | undefined = ['case', 
    ['!=', ['feature-state', 'value'], null],
    ['interpolate',
      ['linear'],
      ['feature-state','value'],
      ...themeColors.map((c:any,i:any,a:any)=>[(i/a.length), c]).flat()
    ], 
  'rgba(0,0,0,0.2)']

  return <>

    <Layer 
      id='city_data'
      source='city'
      source-layer='public.city'
      type='fill'
      paint={{
        "fill-color": colorInt,
        "fill-opacity": [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,1,10,0.2
        ],
        "fill-outline-color":'rgba(0,0,0,0.03)'
      }}
      layout={{visibility:'visible'}}
      before='admin-1-boundary-bg'
    />
  </>
}