import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  float8: any;
  geography: any;
  geometry: any;
};


/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};


/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};


export type Geography_Cast_Exp = {
  geometry?: Maybe<Geometry_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: Maybe<Geography_Cast_Exp>;
  _eq?: Maybe<Scalars['geography']>;
  _gt?: Maybe<Scalars['geography']>;
  _gte?: Maybe<Scalars['geography']>;
  _in?: Maybe<Array<Scalars['geography']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geography']>;
  _lte?: Maybe<Scalars['geography']>;
  _neq?: Maybe<Scalars['geography']>;
  _nin?: Maybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: Maybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: Maybe<Scalars['geography']>;
};


export type Geometry_Cast_Exp = {
  geography?: Maybe<Geography_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: Maybe<Geometry_Cast_Exp>;
  _eq?: Maybe<Scalars['geometry']>;
  _gt?: Maybe<Scalars['geometry']>;
  _gte?: Maybe<Scalars['geometry']>;
  _in?: Maybe<Array<Scalars['geometry']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geometry']>;
  _lte?: Maybe<Scalars['geometry']>;
  _neq?: Maybe<Scalars['geometry']>;
  _nin?: Maybe<Array<Scalars['geometry']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: Maybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: Maybe<Scalars['geometry']>;
  /** does the column contain the given geometry value */
  _st_contains?: Maybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _st_crosses?: Maybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: Maybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: Maybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: Maybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: Maybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: Maybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within?: Maybe<Scalars['geometry']>;
};

/** columns and relationships of "lad" */
export type Lad = {
  geometry?: Maybe<Scalars['geometry']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "lad" */
export type Lad_Aggregate = {
  aggregate?: Maybe<Lad_Aggregate_Fields>;
  nodes: Array<Lad>;
};

/** aggregate fields of "lad" */
export type Lad_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Lad_Max_Fields>;
  min?: Maybe<Lad_Min_Fields>;
};


/** aggregate fields of "lad" */
export type Lad_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lad_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "lad". All fields are combined with a logical 'AND'. */
export type Lad_Bool_Exp = {
  _and?: Maybe<Array<Lad_Bool_Exp>>;
  _not?: Maybe<Lad_Bool_Exp>;
  _or?: Maybe<Array<Lad_Bool_Exp>>;
  geometry?: Maybe<Geometry_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Lad_Max_Fields = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Lad_Min_Fields = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "lad". */
export type Lad_Order_By = {
  geometry?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** select columns of table "lad" */
export enum Lad_Select_Column {
  /** column name */
  Geometry = 'geometry',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  /** fetch data from the table: "lad" */
  lad: Array<Lad>;
  /** fetch aggregated fields from the table: "lad" */
  lad_aggregate: Lad_Aggregate;
  /** fetch data from the table: "salary_residuals_by_city" */
  salary_residuals_by_city: Array<Salary_Residuals_By_City>;
  /** fetch aggregated fields from the table: "salary_residuals_by_city" */
  salary_residuals_by_city_aggregate: Salary_Residuals_By_City_Aggregate;
  /** fetch data from the table: "salary_residuals_by_lad" */
  salary_residuals_by_lad: Array<Salary_Residuals_By_Lad>;
  /** fetch aggregated fields from the table: "salary_residuals_by_lad" */
  salary_residuals_by_lad_aggregate: Salary_Residuals_By_Lad_Aggregate;
  /** fetch data from the table: "skill_counts" */
  skill_counts: Array<Skill_Counts>;
  /** fetch aggregated fields from the table: "skill_counts" */
  skill_counts_aggregate: Skill_Counts_Aggregate;
  /** fetch data from the table: "skill_counts_by_sic" */
  skill_counts_by_sic: Array<Skill_Counts_By_Sic>;
  /** fetch aggregated fields from the table: "skill_counts_by_sic" */
  skill_counts_by_sic_aggregate: Skill_Counts_By_Sic_Aggregate;
  /** fetch data from the table: "skill_pricing_64_651_691" */
  skill_pricing_64_651_691: Array<Skill_Pricing_64_651_691>;
  /** fetch aggregated fields from the table: "skill_pricing_64_651_691" */
  skill_pricing_64_651_691_aggregate: Skill_Pricing_64_651_691_Aggregate;
  /** fetch data from the table: "skill_pricing_by_city" */
  skill_pricing_by_city: Array<Skill_Pricing_By_City>;
  /** fetch aggregated fields from the table: "skill_pricing_by_city" */
  skill_pricing_by_city_aggregate: Skill_Pricing_By_City_Aggregate;
  /** fetch data from the table: "skill_pricing_by_lad" */
  skill_pricing_by_lad: Array<Skill_Pricing_By_Lad>;
  /** fetch aggregated fields from the table: "skill_pricing_by_lad" */
  skill_pricing_by_lad_aggregate: Skill_Pricing_By_Lad_Aggregate;
  /** fetch data from the table: "skill_pricing_sic" */
  skill_pricing_sic: Array<Skill_Pricing_Sic>;
  /** fetch aggregated fields from the table: "skill_pricing_sic" */
  skill_pricing_sic_aggregate: Skill_Pricing_Sic_Aggregate;
  /** fetch data from the table: "tiles_salary_residuals_lad" */
  tiles_salary_residuals_lad: Array<Tiles_Salary_Residuals_Lad>;
  /** fetch aggregated fields from the table: "tiles_salary_residuals_lad" */
  tiles_salary_residuals_lad_aggregate: Tiles_Salary_Residuals_Lad_Aggregate;
};


export type Query_RootLadArgs = {
  distinct_on?: Maybe<Array<Lad_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lad_Order_By>>;
  where?: Maybe<Lad_Bool_Exp>;
};


export type Query_RootLad_AggregateArgs = {
  distinct_on?: Maybe<Array<Lad_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lad_Order_By>>;
  where?: Maybe<Lad_Bool_Exp>;
};


export type Query_RootSalary_Residuals_By_CityArgs = {
  distinct_on?: Maybe<Array<Salary_Residuals_By_City_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Salary_Residuals_By_City_Order_By>>;
  where?: Maybe<Salary_Residuals_By_City_Bool_Exp>;
};


export type Query_RootSalary_Residuals_By_City_AggregateArgs = {
  distinct_on?: Maybe<Array<Salary_Residuals_By_City_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Salary_Residuals_By_City_Order_By>>;
  where?: Maybe<Salary_Residuals_By_City_Bool_Exp>;
};


export type Query_RootSalary_Residuals_By_LadArgs = {
  distinct_on?: Maybe<Array<Salary_Residuals_By_Lad_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Salary_Residuals_By_Lad_Order_By>>;
  where?: Maybe<Salary_Residuals_By_Lad_Bool_Exp>;
};


export type Query_RootSalary_Residuals_By_Lad_AggregateArgs = {
  distinct_on?: Maybe<Array<Salary_Residuals_By_Lad_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Salary_Residuals_By_Lad_Order_By>>;
  where?: Maybe<Salary_Residuals_By_Lad_Bool_Exp>;
};


export type Query_RootSkill_CountsArgs = {
  distinct_on?: Maybe<Array<Skill_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Counts_Order_By>>;
  where?: Maybe<Skill_Counts_Bool_Exp>;
};


export type Query_RootSkill_Counts_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Counts_Order_By>>;
  where?: Maybe<Skill_Counts_Bool_Exp>;
};


export type Query_RootSkill_Counts_By_SicArgs = {
  distinct_on?: Maybe<Array<Skill_Counts_By_Sic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Counts_By_Sic_Order_By>>;
  where?: Maybe<Skill_Counts_By_Sic_Bool_Exp>;
};


export type Query_RootSkill_Counts_By_Sic_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Counts_By_Sic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Counts_By_Sic_Order_By>>;
  where?: Maybe<Skill_Counts_By_Sic_Bool_Exp>;
};


export type Query_RootSkill_Pricing_64_651_691Args = {
  distinct_on?: Maybe<Array<Skill_Pricing_64_651_691_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Pricing_64_651_691_Order_By>>;
  where?: Maybe<Skill_Pricing_64_651_691_Bool_Exp>;
};


export type Query_RootSkill_Pricing_64_651_691_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Pricing_64_651_691_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Pricing_64_651_691_Order_By>>;
  where?: Maybe<Skill_Pricing_64_651_691_Bool_Exp>;
};


export type Query_RootSkill_Pricing_By_CityArgs = {
  distinct_on?: Maybe<Array<Skill_Pricing_By_City_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Pricing_By_City_Order_By>>;
  where?: Maybe<Skill_Pricing_By_City_Bool_Exp>;
};


export type Query_RootSkill_Pricing_By_City_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Pricing_By_City_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Pricing_By_City_Order_By>>;
  where?: Maybe<Skill_Pricing_By_City_Bool_Exp>;
};


export type Query_RootSkill_Pricing_By_LadArgs = {
  distinct_on?: Maybe<Array<Skill_Pricing_By_Lad_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Pricing_By_Lad_Order_By>>;
  where?: Maybe<Skill_Pricing_By_Lad_Bool_Exp>;
};


export type Query_RootSkill_Pricing_By_Lad_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Pricing_By_Lad_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Pricing_By_Lad_Order_By>>;
  where?: Maybe<Skill_Pricing_By_Lad_Bool_Exp>;
};


export type Query_RootSkill_Pricing_SicArgs = {
  distinct_on?: Maybe<Array<Skill_Pricing_Sic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Pricing_Sic_Order_By>>;
  where?: Maybe<Skill_Pricing_Sic_Bool_Exp>;
};


export type Query_RootSkill_Pricing_Sic_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Pricing_Sic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Pricing_Sic_Order_By>>;
  where?: Maybe<Skill_Pricing_Sic_Bool_Exp>;
};


export type Query_RootTiles_Salary_Residuals_LadArgs = {
  distinct_on?: Maybe<Array<Tiles_Salary_Residuals_Lad_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tiles_Salary_Residuals_Lad_Order_By>>;
  where?: Maybe<Tiles_Salary_Residuals_Lad_Bool_Exp>;
};


export type Query_RootTiles_Salary_Residuals_Lad_AggregateArgs = {
  distinct_on?: Maybe<Array<Tiles_Salary_Residuals_Lad_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tiles_Salary_Residuals_Lad_Order_By>>;
  where?: Maybe<Tiles_Salary_Residuals_Lad_Bool_Exp>;
};

/** columns and relationships of "salary_residuals_by_city" */
export type Salary_Residuals_By_City = {
  CanonCity?: Maybe<Scalars['String']>;
  SICCode?: Maybe<Scalars['String']>;
  TCITY15CD?: Maybe<Scalars['String']>;
  loc_mean_salary?: Maybe<Scalars['float8']>;
  loc_salary_sd?: Maybe<Scalars['float8']>;
  n_ads?: Maybe<Scalars['float8']>;
  r_mean?: Maybe<Scalars['float8']>;
  r_sd?: Maybe<Scalars['float8']>;
  years?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "salary_residuals_by_city" */
export type Salary_Residuals_By_City_Aggregate = {
  aggregate?: Maybe<Salary_Residuals_By_City_Aggregate_Fields>;
  nodes: Array<Salary_Residuals_By_City>;
};

/** aggregate fields of "salary_residuals_by_city" */
export type Salary_Residuals_By_City_Aggregate_Fields = {
  avg?: Maybe<Salary_Residuals_By_City_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Salary_Residuals_By_City_Max_Fields>;
  min?: Maybe<Salary_Residuals_By_City_Min_Fields>;
  stddev?: Maybe<Salary_Residuals_By_City_Stddev_Fields>;
  stddev_pop?: Maybe<Salary_Residuals_By_City_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Salary_Residuals_By_City_Stddev_Samp_Fields>;
  sum?: Maybe<Salary_Residuals_By_City_Sum_Fields>;
  var_pop?: Maybe<Salary_Residuals_By_City_Var_Pop_Fields>;
  var_samp?: Maybe<Salary_Residuals_By_City_Var_Samp_Fields>;
  variance?: Maybe<Salary_Residuals_By_City_Variance_Fields>;
};


/** aggregate fields of "salary_residuals_by_city" */
export type Salary_Residuals_By_City_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Salary_Residuals_By_City_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Salary_Residuals_By_City_Avg_Fields = {
  loc_mean_salary?: Maybe<Scalars['Float']>;
  loc_salary_sd?: Maybe<Scalars['Float']>;
  n_ads?: Maybe<Scalars['Float']>;
  r_mean?: Maybe<Scalars['Float']>;
  r_sd?: Maybe<Scalars['Float']>;
  years?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "salary_residuals_by_city". All fields are combined with a logical 'AND'. */
export type Salary_Residuals_By_City_Bool_Exp = {
  CanonCity?: Maybe<String_Comparison_Exp>;
  SICCode?: Maybe<String_Comparison_Exp>;
  TCITY15CD?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Salary_Residuals_By_City_Bool_Exp>>;
  _not?: Maybe<Salary_Residuals_By_City_Bool_Exp>;
  _or?: Maybe<Array<Salary_Residuals_By_City_Bool_Exp>>;
  loc_mean_salary?: Maybe<Float8_Comparison_Exp>;
  loc_salary_sd?: Maybe<Float8_Comparison_Exp>;
  n_ads?: Maybe<Float8_Comparison_Exp>;
  r_mean?: Maybe<Float8_Comparison_Exp>;
  r_sd?: Maybe<Float8_Comparison_Exp>;
  years?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Salary_Residuals_By_City_Max_Fields = {
  CanonCity?: Maybe<Scalars['String']>;
  SICCode?: Maybe<Scalars['String']>;
  TCITY15CD?: Maybe<Scalars['String']>;
  loc_mean_salary?: Maybe<Scalars['float8']>;
  loc_salary_sd?: Maybe<Scalars['float8']>;
  n_ads?: Maybe<Scalars['float8']>;
  r_mean?: Maybe<Scalars['float8']>;
  r_sd?: Maybe<Scalars['float8']>;
  years?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Salary_Residuals_By_City_Min_Fields = {
  CanonCity?: Maybe<Scalars['String']>;
  SICCode?: Maybe<Scalars['String']>;
  TCITY15CD?: Maybe<Scalars['String']>;
  loc_mean_salary?: Maybe<Scalars['float8']>;
  loc_salary_sd?: Maybe<Scalars['float8']>;
  n_ads?: Maybe<Scalars['float8']>;
  r_mean?: Maybe<Scalars['float8']>;
  r_sd?: Maybe<Scalars['float8']>;
  years?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "salary_residuals_by_city". */
export type Salary_Residuals_By_City_Order_By = {
  CanonCity?: Maybe<Order_By>;
  SICCode?: Maybe<Order_By>;
  TCITY15CD?: Maybe<Order_By>;
  loc_mean_salary?: Maybe<Order_By>;
  loc_salary_sd?: Maybe<Order_By>;
  n_ads?: Maybe<Order_By>;
  r_mean?: Maybe<Order_By>;
  r_sd?: Maybe<Order_By>;
  years?: Maybe<Order_By>;
};

/** select columns of table "salary_residuals_by_city" */
export enum Salary_Residuals_By_City_Select_Column {
  /** column name */
  CanonCity = 'CanonCity',
  /** column name */
  SicCode = 'SICCode',
  /** column name */
  Tcity15Cd = 'TCITY15CD',
  /** column name */
  LocMeanSalary = 'loc_mean_salary',
  /** column name */
  LocSalarySd = 'loc_salary_sd',
  /** column name */
  NAds = 'n_ads',
  /** column name */
  RMean = 'r_mean',
  /** column name */
  RSd = 'r_sd',
  /** column name */
  Years = 'years'
}

/** aggregate stddev on columns */
export type Salary_Residuals_By_City_Stddev_Fields = {
  loc_mean_salary?: Maybe<Scalars['Float']>;
  loc_salary_sd?: Maybe<Scalars['Float']>;
  n_ads?: Maybe<Scalars['Float']>;
  r_mean?: Maybe<Scalars['Float']>;
  r_sd?: Maybe<Scalars['Float']>;
  years?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Salary_Residuals_By_City_Stddev_Pop_Fields = {
  loc_mean_salary?: Maybe<Scalars['Float']>;
  loc_salary_sd?: Maybe<Scalars['Float']>;
  n_ads?: Maybe<Scalars['Float']>;
  r_mean?: Maybe<Scalars['Float']>;
  r_sd?: Maybe<Scalars['Float']>;
  years?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Salary_Residuals_By_City_Stddev_Samp_Fields = {
  loc_mean_salary?: Maybe<Scalars['Float']>;
  loc_salary_sd?: Maybe<Scalars['Float']>;
  n_ads?: Maybe<Scalars['Float']>;
  r_mean?: Maybe<Scalars['Float']>;
  r_sd?: Maybe<Scalars['Float']>;
  years?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Salary_Residuals_By_City_Sum_Fields = {
  loc_mean_salary?: Maybe<Scalars['float8']>;
  loc_salary_sd?: Maybe<Scalars['float8']>;
  n_ads?: Maybe<Scalars['float8']>;
  r_mean?: Maybe<Scalars['float8']>;
  r_sd?: Maybe<Scalars['float8']>;
  years?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Salary_Residuals_By_City_Var_Pop_Fields = {
  loc_mean_salary?: Maybe<Scalars['Float']>;
  loc_salary_sd?: Maybe<Scalars['Float']>;
  n_ads?: Maybe<Scalars['Float']>;
  r_mean?: Maybe<Scalars['Float']>;
  r_sd?: Maybe<Scalars['Float']>;
  years?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Salary_Residuals_By_City_Var_Samp_Fields = {
  loc_mean_salary?: Maybe<Scalars['Float']>;
  loc_salary_sd?: Maybe<Scalars['Float']>;
  n_ads?: Maybe<Scalars['Float']>;
  r_mean?: Maybe<Scalars['Float']>;
  r_sd?: Maybe<Scalars['Float']>;
  years?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Salary_Residuals_By_City_Variance_Fields = {
  loc_mean_salary?: Maybe<Scalars['Float']>;
  loc_salary_sd?: Maybe<Scalars['Float']>;
  n_ads?: Maybe<Scalars['Float']>;
  r_mean?: Maybe<Scalars['Float']>;
  r_sd?: Maybe<Scalars['Float']>;
  years?: Maybe<Scalars['Float']>;
};

/**
 * This tables contains the work from Gatis.
 *
 *
 * columns and relationships of "salary_residuals_by_lad"
 */
export type Salary_Residuals_By_Lad = {
  lad_code?: Maybe<Scalars['String']>;
  /** From the Burning Glass data. */
  lad_name?: Maybe<Scalars['String']>;
  loc_mean_salary?: Maybe<Scalars['float8']>;
  loc_salary_sd?: Maybe<Scalars['float8']>;
  n_ads?: Maybe<Scalars['float8']>;
  r_mean?: Maybe<Scalars['float8']>;
  r_sd?: Maybe<Scalars['float8']>;
  sic?: Maybe<Scalars['String']>;
  years?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "salary_residuals_by_lad" */
export type Salary_Residuals_By_Lad_Aggregate = {
  aggregate?: Maybe<Salary_Residuals_By_Lad_Aggregate_Fields>;
  nodes: Array<Salary_Residuals_By_Lad>;
};

/** aggregate fields of "salary_residuals_by_lad" */
export type Salary_Residuals_By_Lad_Aggregate_Fields = {
  avg?: Maybe<Salary_Residuals_By_Lad_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Salary_Residuals_By_Lad_Max_Fields>;
  min?: Maybe<Salary_Residuals_By_Lad_Min_Fields>;
  stddev?: Maybe<Salary_Residuals_By_Lad_Stddev_Fields>;
  stddev_pop?: Maybe<Salary_Residuals_By_Lad_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Salary_Residuals_By_Lad_Stddev_Samp_Fields>;
  sum?: Maybe<Salary_Residuals_By_Lad_Sum_Fields>;
  var_pop?: Maybe<Salary_Residuals_By_Lad_Var_Pop_Fields>;
  var_samp?: Maybe<Salary_Residuals_By_Lad_Var_Samp_Fields>;
  variance?: Maybe<Salary_Residuals_By_Lad_Variance_Fields>;
};


/** aggregate fields of "salary_residuals_by_lad" */
export type Salary_Residuals_By_Lad_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Salary_Residuals_By_Lad_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Salary_Residuals_By_Lad_Avg_Fields = {
  loc_mean_salary?: Maybe<Scalars['Float']>;
  loc_salary_sd?: Maybe<Scalars['Float']>;
  n_ads?: Maybe<Scalars['Float']>;
  r_mean?: Maybe<Scalars['Float']>;
  r_sd?: Maybe<Scalars['Float']>;
  years?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "salary_residuals_by_lad". All fields are combined with a logical 'AND'. */
export type Salary_Residuals_By_Lad_Bool_Exp = {
  _and?: Maybe<Array<Salary_Residuals_By_Lad_Bool_Exp>>;
  _not?: Maybe<Salary_Residuals_By_Lad_Bool_Exp>;
  _or?: Maybe<Array<Salary_Residuals_By_Lad_Bool_Exp>>;
  lad_code?: Maybe<String_Comparison_Exp>;
  lad_name?: Maybe<String_Comparison_Exp>;
  loc_mean_salary?: Maybe<Float8_Comparison_Exp>;
  loc_salary_sd?: Maybe<Float8_Comparison_Exp>;
  n_ads?: Maybe<Float8_Comparison_Exp>;
  r_mean?: Maybe<Float8_Comparison_Exp>;
  r_sd?: Maybe<Float8_Comparison_Exp>;
  sic?: Maybe<String_Comparison_Exp>;
  years?: Maybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Salary_Residuals_By_Lad_Max_Fields = {
  lad_code?: Maybe<Scalars['String']>;
  /** From the Burning Glass data. */
  lad_name?: Maybe<Scalars['String']>;
  loc_mean_salary?: Maybe<Scalars['float8']>;
  loc_salary_sd?: Maybe<Scalars['float8']>;
  n_ads?: Maybe<Scalars['float8']>;
  r_mean?: Maybe<Scalars['float8']>;
  r_sd?: Maybe<Scalars['float8']>;
  sic?: Maybe<Scalars['String']>;
  years?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Salary_Residuals_By_Lad_Min_Fields = {
  lad_code?: Maybe<Scalars['String']>;
  /** From the Burning Glass data. */
  lad_name?: Maybe<Scalars['String']>;
  loc_mean_salary?: Maybe<Scalars['float8']>;
  loc_salary_sd?: Maybe<Scalars['float8']>;
  n_ads?: Maybe<Scalars['float8']>;
  r_mean?: Maybe<Scalars['float8']>;
  r_sd?: Maybe<Scalars['float8']>;
  sic?: Maybe<Scalars['String']>;
  years?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "salary_residuals_by_lad". */
export type Salary_Residuals_By_Lad_Order_By = {
  lad_code?: Maybe<Order_By>;
  lad_name?: Maybe<Order_By>;
  loc_mean_salary?: Maybe<Order_By>;
  loc_salary_sd?: Maybe<Order_By>;
  n_ads?: Maybe<Order_By>;
  r_mean?: Maybe<Order_By>;
  r_sd?: Maybe<Order_By>;
  sic?: Maybe<Order_By>;
  years?: Maybe<Order_By>;
};

/** select columns of table "salary_residuals_by_lad" */
export enum Salary_Residuals_By_Lad_Select_Column {
  /** column name */
  LadCode = 'lad_code',
  /** column name */
  LadName = 'lad_name',
  /** column name */
  LocMeanSalary = 'loc_mean_salary',
  /** column name */
  LocSalarySd = 'loc_salary_sd',
  /** column name */
  NAds = 'n_ads',
  /** column name */
  RMean = 'r_mean',
  /** column name */
  RSd = 'r_sd',
  /** column name */
  Sic = 'sic',
  /** column name */
  Years = 'years'
}

/** aggregate stddev on columns */
export type Salary_Residuals_By_Lad_Stddev_Fields = {
  loc_mean_salary?: Maybe<Scalars['Float']>;
  loc_salary_sd?: Maybe<Scalars['Float']>;
  n_ads?: Maybe<Scalars['Float']>;
  r_mean?: Maybe<Scalars['Float']>;
  r_sd?: Maybe<Scalars['Float']>;
  years?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Salary_Residuals_By_Lad_Stddev_Pop_Fields = {
  loc_mean_salary?: Maybe<Scalars['Float']>;
  loc_salary_sd?: Maybe<Scalars['Float']>;
  n_ads?: Maybe<Scalars['Float']>;
  r_mean?: Maybe<Scalars['Float']>;
  r_sd?: Maybe<Scalars['Float']>;
  years?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Salary_Residuals_By_Lad_Stddev_Samp_Fields = {
  loc_mean_salary?: Maybe<Scalars['Float']>;
  loc_salary_sd?: Maybe<Scalars['Float']>;
  n_ads?: Maybe<Scalars['Float']>;
  r_mean?: Maybe<Scalars['Float']>;
  r_sd?: Maybe<Scalars['Float']>;
  years?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Salary_Residuals_By_Lad_Sum_Fields = {
  loc_mean_salary?: Maybe<Scalars['float8']>;
  loc_salary_sd?: Maybe<Scalars['float8']>;
  n_ads?: Maybe<Scalars['float8']>;
  r_mean?: Maybe<Scalars['float8']>;
  r_sd?: Maybe<Scalars['float8']>;
  years?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Salary_Residuals_By_Lad_Var_Pop_Fields = {
  loc_mean_salary?: Maybe<Scalars['Float']>;
  loc_salary_sd?: Maybe<Scalars['Float']>;
  n_ads?: Maybe<Scalars['Float']>;
  r_mean?: Maybe<Scalars['Float']>;
  r_sd?: Maybe<Scalars['Float']>;
  years?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Salary_Residuals_By_Lad_Var_Samp_Fields = {
  loc_mean_salary?: Maybe<Scalars['Float']>;
  loc_salary_sd?: Maybe<Scalars['Float']>;
  n_ads?: Maybe<Scalars['Float']>;
  r_mean?: Maybe<Scalars['Float']>;
  r_sd?: Maybe<Scalars['Float']>;
  years?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Salary_Residuals_By_Lad_Variance_Fields = {
  loc_mean_salary?: Maybe<Scalars['Float']>;
  loc_salary_sd?: Maybe<Scalars['Float']>;
  n_ads?: Maybe<Scalars['Float']>;
  r_mean?: Maybe<Scalars['Float']>;
  r_sd?: Maybe<Scalars['Float']>;
  years?: Maybe<Scalars['Float']>;
};

/**
 * Counts and proportions of skills in adverts across the whole of the UK
 *
 *
 * columns and relationships of "skill_counts"
 */
export type Skill_Counts = {
  codeLabel?: Maybe<Scalars['String']>;
  codeType?: Maybe<Scalars['String']>;
  dateLabel?: Maybe<Scalars['Int']>;
  dateType?: Maybe<Scalars['String']>;
  geographyLabel?: Maybe<Scalars['String']>;
  geographyType?: Maybe<Scalars['String']>;
  observationCount?: Maybe<Scalars['float8']>;
  proportion?: Maybe<Scalars['float8']>;
  skill?: Maybe<Scalars['String']>;
};

/** aggregated selection of "skill_counts" */
export type Skill_Counts_Aggregate = {
  aggregate?: Maybe<Skill_Counts_Aggregate_Fields>;
  nodes: Array<Skill_Counts>;
};

/** aggregate fields of "skill_counts" */
export type Skill_Counts_Aggregate_Fields = {
  avg?: Maybe<Skill_Counts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Skill_Counts_Max_Fields>;
  min?: Maybe<Skill_Counts_Min_Fields>;
  stddev?: Maybe<Skill_Counts_Stddev_Fields>;
  stddev_pop?: Maybe<Skill_Counts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Skill_Counts_Stddev_Samp_Fields>;
  sum?: Maybe<Skill_Counts_Sum_Fields>;
  var_pop?: Maybe<Skill_Counts_Var_Pop_Fields>;
  var_samp?: Maybe<Skill_Counts_Var_Samp_Fields>;
  variance?: Maybe<Skill_Counts_Variance_Fields>;
};


/** aggregate fields of "skill_counts" */
export type Skill_Counts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Skill_Counts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Skill_Counts_Avg_Fields = {
  dateLabel?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "skill_counts". All fields are combined with a logical 'AND'. */
export type Skill_Counts_Bool_Exp = {
  _and?: Maybe<Array<Skill_Counts_Bool_Exp>>;
  _not?: Maybe<Skill_Counts_Bool_Exp>;
  _or?: Maybe<Array<Skill_Counts_Bool_Exp>>;
  codeLabel?: Maybe<String_Comparison_Exp>;
  codeType?: Maybe<String_Comparison_Exp>;
  dateLabel?: Maybe<Int_Comparison_Exp>;
  dateType?: Maybe<String_Comparison_Exp>;
  geographyLabel?: Maybe<String_Comparison_Exp>;
  geographyType?: Maybe<String_Comparison_Exp>;
  observationCount?: Maybe<Float8_Comparison_Exp>;
  proportion?: Maybe<Float8_Comparison_Exp>;
  skill?: Maybe<String_Comparison_Exp>;
};

/** columns and relationships of "skill_counts_by_sic" */
export type Skill_Counts_By_Sic = {
  count?: Maybe<Scalars['float8']>;
  proportion?: Maybe<Scalars['float8']>;
  sic?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "skill_counts_by_sic" */
export type Skill_Counts_By_Sic_Aggregate = {
  aggregate?: Maybe<Skill_Counts_By_Sic_Aggregate_Fields>;
  nodes: Array<Skill_Counts_By_Sic>;
};

/** aggregate fields of "skill_counts_by_sic" */
export type Skill_Counts_By_Sic_Aggregate_Fields = {
  avg?: Maybe<Skill_Counts_By_Sic_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Skill_Counts_By_Sic_Max_Fields>;
  min?: Maybe<Skill_Counts_By_Sic_Min_Fields>;
  stddev?: Maybe<Skill_Counts_By_Sic_Stddev_Fields>;
  stddev_pop?: Maybe<Skill_Counts_By_Sic_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Skill_Counts_By_Sic_Stddev_Samp_Fields>;
  sum?: Maybe<Skill_Counts_By_Sic_Sum_Fields>;
  var_pop?: Maybe<Skill_Counts_By_Sic_Var_Pop_Fields>;
  var_samp?: Maybe<Skill_Counts_By_Sic_Var_Samp_Fields>;
  variance?: Maybe<Skill_Counts_By_Sic_Variance_Fields>;
};


/** aggregate fields of "skill_counts_by_sic" */
export type Skill_Counts_By_Sic_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Skill_Counts_By_Sic_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Skill_Counts_By_Sic_Avg_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "skill_counts_by_sic". All fields are combined with a logical 'AND'. */
export type Skill_Counts_By_Sic_Bool_Exp = {
  _and?: Maybe<Array<Skill_Counts_By_Sic_Bool_Exp>>;
  _not?: Maybe<Skill_Counts_By_Sic_Bool_Exp>;
  _or?: Maybe<Array<Skill_Counts_By_Sic_Bool_Exp>>;
  count?: Maybe<Float8_Comparison_Exp>;
  proportion?: Maybe<Float8_Comparison_Exp>;
  sic?: Maybe<String_Comparison_Exp>;
  year?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Skill_Counts_By_Sic_Max_Fields = {
  count?: Maybe<Scalars['float8']>;
  proportion?: Maybe<Scalars['float8']>;
  sic?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Skill_Counts_By_Sic_Min_Fields = {
  count?: Maybe<Scalars['float8']>;
  proportion?: Maybe<Scalars['float8']>;
  sic?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "skill_counts_by_sic". */
export type Skill_Counts_By_Sic_Order_By = {
  count?: Maybe<Order_By>;
  proportion?: Maybe<Order_By>;
  sic?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** select columns of table "skill_counts_by_sic" */
export enum Skill_Counts_By_Sic_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Proportion = 'proportion',
  /** column name */
  Sic = 'sic',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type Skill_Counts_By_Sic_Stddev_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Skill_Counts_By_Sic_Stddev_Pop_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Skill_Counts_By_Sic_Stddev_Samp_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Skill_Counts_By_Sic_Sum_Fields = {
  count?: Maybe<Scalars['float8']>;
  proportion?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Skill_Counts_By_Sic_Var_Pop_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Skill_Counts_By_Sic_Var_Samp_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Skill_Counts_By_Sic_Variance_Fields = {
  count?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Skill_Counts_Max_Fields = {
  codeLabel?: Maybe<Scalars['String']>;
  codeType?: Maybe<Scalars['String']>;
  dateLabel?: Maybe<Scalars['Int']>;
  dateType?: Maybe<Scalars['String']>;
  geographyLabel?: Maybe<Scalars['String']>;
  geographyType?: Maybe<Scalars['String']>;
  observationCount?: Maybe<Scalars['float8']>;
  proportion?: Maybe<Scalars['float8']>;
  skill?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Skill_Counts_Min_Fields = {
  codeLabel?: Maybe<Scalars['String']>;
  codeType?: Maybe<Scalars['String']>;
  dateLabel?: Maybe<Scalars['Int']>;
  dateType?: Maybe<Scalars['String']>;
  geographyLabel?: Maybe<Scalars['String']>;
  geographyType?: Maybe<Scalars['String']>;
  observationCount?: Maybe<Scalars['float8']>;
  proportion?: Maybe<Scalars['float8']>;
  skill?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "skill_counts". */
export type Skill_Counts_Order_By = {
  codeLabel?: Maybe<Order_By>;
  codeType?: Maybe<Order_By>;
  dateLabel?: Maybe<Order_By>;
  dateType?: Maybe<Order_By>;
  geographyLabel?: Maybe<Order_By>;
  geographyType?: Maybe<Order_By>;
  observationCount?: Maybe<Order_By>;
  proportion?: Maybe<Order_By>;
  skill?: Maybe<Order_By>;
};

/** select columns of table "skill_counts" */
export enum Skill_Counts_Select_Column {
  /** column name */
  CodeLabel = 'codeLabel',
  /** column name */
  CodeType = 'codeType',
  /** column name */
  DateLabel = 'dateLabel',
  /** column name */
  DateType = 'dateType',
  /** column name */
  GeographyLabel = 'geographyLabel',
  /** column name */
  GeographyType = 'geographyType',
  /** column name */
  ObservationCount = 'observationCount',
  /** column name */
  Proportion = 'proportion',
  /** column name */
  Skill = 'skill'
}

/** aggregate stddev on columns */
export type Skill_Counts_Stddev_Fields = {
  dateLabel?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Skill_Counts_Stddev_Pop_Fields = {
  dateLabel?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Skill_Counts_Stddev_Samp_Fields = {
  dateLabel?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Skill_Counts_Sum_Fields = {
  dateLabel?: Maybe<Scalars['Int']>;
  observationCount?: Maybe<Scalars['float8']>;
  proportion?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Skill_Counts_Var_Pop_Fields = {
  dateLabel?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Skill_Counts_Var_Samp_Fields = {
  dateLabel?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Skill_Counts_Variance_Fields = {
  dateLabel?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
  proportion?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "skill_pricing_64_651_691" */
export type Skill_Pricing_64_651_691 = {
  codeLabel?: Maybe<Scalars['String']>;
  codeType?: Maybe<Scalars['String']>;
  coef?: Maybe<Scalars['String']>;
  /** An object relationship */
  counts?: Maybe<Skill_Counts>;
  dateLabel?: Maybe<Scalars['Int']>;
  dateType?: Maybe<Scalars['String']>;
  geographyLabel?: Maybe<Scalars['String']>;
  geographyType?: Maybe<Scalars['String']>;
  intercept?: Maybe<Scalars['float8']>;
  observationCount?: Maybe<Scalars['float8']>;
  skill?: Maybe<Scalars['String']>;
};

/** aggregated selection of "skill_pricing_64_651_691" */
export type Skill_Pricing_64_651_691_Aggregate = {
  aggregate?: Maybe<Skill_Pricing_64_651_691_Aggregate_Fields>;
  nodes: Array<Skill_Pricing_64_651_691>;
};

/** aggregate fields of "skill_pricing_64_651_691" */
export type Skill_Pricing_64_651_691_Aggregate_Fields = {
  avg?: Maybe<Skill_Pricing_64_651_691_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Skill_Pricing_64_651_691_Max_Fields>;
  min?: Maybe<Skill_Pricing_64_651_691_Min_Fields>;
  stddev?: Maybe<Skill_Pricing_64_651_691_Stddev_Fields>;
  stddev_pop?: Maybe<Skill_Pricing_64_651_691_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Skill_Pricing_64_651_691_Stddev_Samp_Fields>;
  sum?: Maybe<Skill_Pricing_64_651_691_Sum_Fields>;
  var_pop?: Maybe<Skill_Pricing_64_651_691_Var_Pop_Fields>;
  var_samp?: Maybe<Skill_Pricing_64_651_691_Var_Samp_Fields>;
  variance?: Maybe<Skill_Pricing_64_651_691_Variance_Fields>;
};


/** aggregate fields of "skill_pricing_64_651_691" */
export type Skill_Pricing_64_651_691_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Skill_Pricing_64_651_691_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Skill_Pricing_64_651_691_Avg_Fields = {
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "skill_pricing_64_651_691". All fields are combined with a logical 'AND'. */
export type Skill_Pricing_64_651_691_Bool_Exp = {
  _and?: Maybe<Array<Skill_Pricing_64_651_691_Bool_Exp>>;
  _not?: Maybe<Skill_Pricing_64_651_691_Bool_Exp>;
  _or?: Maybe<Array<Skill_Pricing_64_651_691_Bool_Exp>>;
  codeLabel?: Maybe<String_Comparison_Exp>;
  codeType?: Maybe<String_Comparison_Exp>;
  coef?: Maybe<String_Comparison_Exp>;
  counts?: Maybe<Skill_Counts_Bool_Exp>;
  dateLabel?: Maybe<Int_Comparison_Exp>;
  dateType?: Maybe<String_Comparison_Exp>;
  geographyLabel?: Maybe<String_Comparison_Exp>;
  geographyType?: Maybe<String_Comparison_Exp>;
  intercept?: Maybe<Float8_Comparison_Exp>;
  observationCount?: Maybe<Float8_Comparison_Exp>;
  skill?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Skill_Pricing_64_651_691_Max_Fields = {
  codeLabel?: Maybe<Scalars['String']>;
  codeType?: Maybe<Scalars['String']>;
  coef?: Maybe<Scalars['String']>;
  dateLabel?: Maybe<Scalars['Int']>;
  dateType?: Maybe<Scalars['String']>;
  geographyLabel?: Maybe<Scalars['String']>;
  geographyType?: Maybe<Scalars['String']>;
  intercept?: Maybe<Scalars['float8']>;
  observationCount?: Maybe<Scalars['float8']>;
  skill?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Skill_Pricing_64_651_691_Min_Fields = {
  codeLabel?: Maybe<Scalars['String']>;
  codeType?: Maybe<Scalars['String']>;
  coef?: Maybe<Scalars['String']>;
  dateLabel?: Maybe<Scalars['Int']>;
  dateType?: Maybe<Scalars['String']>;
  geographyLabel?: Maybe<Scalars['String']>;
  geographyType?: Maybe<Scalars['String']>;
  intercept?: Maybe<Scalars['float8']>;
  observationCount?: Maybe<Scalars['float8']>;
  skill?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "skill_pricing_64_651_691". */
export type Skill_Pricing_64_651_691_Order_By = {
  codeLabel?: Maybe<Order_By>;
  codeType?: Maybe<Order_By>;
  coef?: Maybe<Order_By>;
  counts?: Maybe<Skill_Counts_Order_By>;
  dateLabel?: Maybe<Order_By>;
  dateType?: Maybe<Order_By>;
  geographyLabel?: Maybe<Order_By>;
  geographyType?: Maybe<Order_By>;
  intercept?: Maybe<Order_By>;
  observationCount?: Maybe<Order_By>;
  skill?: Maybe<Order_By>;
};

/** select columns of table "skill_pricing_64_651_691" */
export enum Skill_Pricing_64_651_691_Select_Column {
  /** column name */
  CodeLabel = 'codeLabel',
  /** column name */
  CodeType = 'codeType',
  /** column name */
  Coef = 'coef',
  /** column name */
  DateLabel = 'dateLabel',
  /** column name */
  DateType = 'dateType',
  /** column name */
  GeographyLabel = 'geographyLabel',
  /** column name */
  GeographyType = 'geographyType',
  /** column name */
  Intercept = 'intercept',
  /** column name */
  ObservationCount = 'observationCount',
  /** column name */
  Skill = 'skill'
}

/** aggregate stddev on columns */
export type Skill_Pricing_64_651_691_Stddev_Fields = {
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Skill_Pricing_64_651_691_Stddev_Pop_Fields = {
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Skill_Pricing_64_651_691_Stddev_Samp_Fields = {
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Skill_Pricing_64_651_691_Sum_Fields = {
  dateLabel?: Maybe<Scalars['Int']>;
  intercept?: Maybe<Scalars['float8']>;
  observationCount?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Skill_Pricing_64_651_691_Var_Pop_Fields = {
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Skill_Pricing_64_651_691_Var_Samp_Fields = {
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Skill_Pricing_64_651_691_Variance_Fields = {
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "skill_pricing_by_city" */
export type Skill_Pricing_By_City = {
  TCITY15CD?: Maybe<Scalars['String']>;
  codeLabel?: Maybe<Scalars['String']>;
  codeType?: Maybe<Scalars['String']>;
  coef?: Maybe<Scalars['float8']>;
  dateLabel?: Maybe<Scalars['Int']>;
  dateType?: Maybe<Scalars['String']>;
  geographyLabel?: Maybe<Scalars['String']>;
  geographyType?: Maybe<Scalars['String']>;
  intercept?: Maybe<Scalars['float8']>;
  observationCount?: Maybe<Scalars['float8']>;
  skills?: Maybe<Scalars['String']>;
};

/** aggregated selection of "skill_pricing_by_city" */
export type Skill_Pricing_By_City_Aggregate = {
  aggregate?: Maybe<Skill_Pricing_By_City_Aggregate_Fields>;
  nodes: Array<Skill_Pricing_By_City>;
};

/** aggregate fields of "skill_pricing_by_city" */
export type Skill_Pricing_By_City_Aggregate_Fields = {
  avg?: Maybe<Skill_Pricing_By_City_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Skill_Pricing_By_City_Max_Fields>;
  min?: Maybe<Skill_Pricing_By_City_Min_Fields>;
  stddev?: Maybe<Skill_Pricing_By_City_Stddev_Fields>;
  stddev_pop?: Maybe<Skill_Pricing_By_City_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Skill_Pricing_By_City_Stddev_Samp_Fields>;
  sum?: Maybe<Skill_Pricing_By_City_Sum_Fields>;
  var_pop?: Maybe<Skill_Pricing_By_City_Var_Pop_Fields>;
  var_samp?: Maybe<Skill_Pricing_By_City_Var_Samp_Fields>;
  variance?: Maybe<Skill_Pricing_By_City_Variance_Fields>;
};


/** aggregate fields of "skill_pricing_by_city" */
export type Skill_Pricing_By_City_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Skill_Pricing_By_City_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Skill_Pricing_By_City_Avg_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "skill_pricing_by_city". All fields are combined with a logical 'AND'. */
export type Skill_Pricing_By_City_Bool_Exp = {
  TCITY15CD?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Skill_Pricing_By_City_Bool_Exp>>;
  _not?: Maybe<Skill_Pricing_By_City_Bool_Exp>;
  _or?: Maybe<Array<Skill_Pricing_By_City_Bool_Exp>>;
  codeLabel?: Maybe<String_Comparison_Exp>;
  codeType?: Maybe<String_Comparison_Exp>;
  coef?: Maybe<Float8_Comparison_Exp>;
  dateLabel?: Maybe<Int_Comparison_Exp>;
  dateType?: Maybe<String_Comparison_Exp>;
  geographyLabel?: Maybe<String_Comparison_Exp>;
  geographyType?: Maybe<String_Comparison_Exp>;
  intercept?: Maybe<Float8_Comparison_Exp>;
  observationCount?: Maybe<Float8_Comparison_Exp>;
  skills?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Skill_Pricing_By_City_Max_Fields = {
  TCITY15CD?: Maybe<Scalars['String']>;
  codeLabel?: Maybe<Scalars['String']>;
  codeType?: Maybe<Scalars['String']>;
  coef?: Maybe<Scalars['float8']>;
  dateLabel?: Maybe<Scalars['Int']>;
  dateType?: Maybe<Scalars['String']>;
  geographyLabel?: Maybe<Scalars['String']>;
  geographyType?: Maybe<Scalars['String']>;
  intercept?: Maybe<Scalars['float8']>;
  observationCount?: Maybe<Scalars['float8']>;
  skills?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Skill_Pricing_By_City_Min_Fields = {
  TCITY15CD?: Maybe<Scalars['String']>;
  codeLabel?: Maybe<Scalars['String']>;
  codeType?: Maybe<Scalars['String']>;
  coef?: Maybe<Scalars['float8']>;
  dateLabel?: Maybe<Scalars['Int']>;
  dateType?: Maybe<Scalars['String']>;
  geographyLabel?: Maybe<Scalars['String']>;
  geographyType?: Maybe<Scalars['String']>;
  intercept?: Maybe<Scalars['float8']>;
  observationCount?: Maybe<Scalars['float8']>;
  skills?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "skill_pricing_by_city". */
export type Skill_Pricing_By_City_Order_By = {
  TCITY15CD?: Maybe<Order_By>;
  codeLabel?: Maybe<Order_By>;
  codeType?: Maybe<Order_By>;
  coef?: Maybe<Order_By>;
  dateLabel?: Maybe<Order_By>;
  dateType?: Maybe<Order_By>;
  geographyLabel?: Maybe<Order_By>;
  geographyType?: Maybe<Order_By>;
  intercept?: Maybe<Order_By>;
  observationCount?: Maybe<Order_By>;
  skills?: Maybe<Order_By>;
};

/** select columns of table "skill_pricing_by_city" */
export enum Skill_Pricing_By_City_Select_Column {
  /** column name */
  Tcity15Cd = 'TCITY15CD',
  /** column name */
  CodeLabel = 'codeLabel',
  /** column name */
  CodeType = 'codeType',
  /** column name */
  Coef = 'coef',
  /** column name */
  DateLabel = 'dateLabel',
  /** column name */
  DateType = 'dateType',
  /** column name */
  GeographyLabel = 'geographyLabel',
  /** column name */
  GeographyType = 'geographyType',
  /** column name */
  Intercept = 'intercept',
  /** column name */
  ObservationCount = 'observationCount',
  /** column name */
  Skills = 'skills'
}

/** aggregate stddev on columns */
export type Skill_Pricing_By_City_Stddev_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Skill_Pricing_By_City_Stddev_Pop_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Skill_Pricing_By_City_Stddev_Samp_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Skill_Pricing_By_City_Sum_Fields = {
  coef?: Maybe<Scalars['float8']>;
  dateLabel?: Maybe<Scalars['Int']>;
  intercept?: Maybe<Scalars['float8']>;
  observationCount?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Skill_Pricing_By_City_Var_Pop_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Skill_Pricing_By_City_Var_Samp_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Skill_Pricing_By_City_Variance_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "skill_pricing_by_lad" */
export type Skill_Pricing_By_Lad = {
  codeLabel?: Maybe<Scalars['String']>;
  codeType?: Maybe<Scalars['String']>;
  coef?: Maybe<Scalars['float8']>;
  dateLabel?: Maybe<Scalars['Int']>;
  dateType?: Maybe<Scalars['String']>;
  geographyCode?: Maybe<Scalars['String']>;
  geographyLabel?: Maybe<Scalars['String']>;
  geographyType?: Maybe<Scalars['String']>;
  intercept?: Maybe<Scalars['float8']>;
  observationCount?: Maybe<Scalars['float8']>;
  skills?: Maybe<Scalars['String']>;
};

/** aggregated selection of "skill_pricing_by_lad" */
export type Skill_Pricing_By_Lad_Aggregate = {
  aggregate?: Maybe<Skill_Pricing_By_Lad_Aggregate_Fields>;
  nodes: Array<Skill_Pricing_By_Lad>;
};

/** aggregate fields of "skill_pricing_by_lad" */
export type Skill_Pricing_By_Lad_Aggregate_Fields = {
  avg?: Maybe<Skill_Pricing_By_Lad_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Skill_Pricing_By_Lad_Max_Fields>;
  min?: Maybe<Skill_Pricing_By_Lad_Min_Fields>;
  stddev?: Maybe<Skill_Pricing_By_Lad_Stddev_Fields>;
  stddev_pop?: Maybe<Skill_Pricing_By_Lad_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Skill_Pricing_By_Lad_Stddev_Samp_Fields>;
  sum?: Maybe<Skill_Pricing_By_Lad_Sum_Fields>;
  var_pop?: Maybe<Skill_Pricing_By_Lad_Var_Pop_Fields>;
  var_samp?: Maybe<Skill_Pricing_By_Lad_Var_Samp_Fields>;
  variance?: Maybe<Skill_Pricing_By_Lad_Variance_Fields>;
};


/** aggregate fields of "skill_pricing_by_lad" */
export type Skill_Pricing_By_Lad_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Skill_Pricing_By_Lad_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Skill_Pricing_By_Lad_Avg_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "skill_pricing_by_lad". All fields are combined with a logical 'AND'. */
export type Skill_Pricing_By_Lad_Bool_Exp = {
  _and?: Maybe<Array<Skill_Pricing_By_Lad_Bool_Exp>>;
  _not?: Maybe<Skill_Pricing_By_Lad_Bool_Exp>;
  _or?: Maybe<Array<Skill_Pricing_By_Lad_Bool_Exp>>;
  codeLabel?: Maybe<String_Comparison_Exp>;
  codeType?: Maybe<String_Comparison_Exp>;
  coef?: Maybe<Float8_Comparison_Exp>;
  dateLabel?: Maybe<Int_Comparison_Exp>;
  dateType?: Maybe<String_Comparison_Exp>;
  geographyCode?: Maybe<String_Comparison_Exp>;
  geographyLabel?: Maybe<String_Comparison_Exp>;
  geographyType?: Maybe<String_Comparison_Exp>;
  intercept?: Maybe<Float8_Comparison_Exp>;
  observationCount?: Maybe<Float8_Comparison_Exp>;
  skills?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Skill_Pricing_By_Lad_Max_Fields = {
  codeLabel?: Maybe<Scalars['String']>;
  codeType?: Maybe<Scalars['String']>;
  coef?: Maybe<Scalars['float8']>;
  dateLabel?: Maybe<Scalars['Int']>;
  dateType?: Maybe<Scalars['String']>;
  geographyCode?: Maybe<Scalars['String']>;
  geographyLabel?: Maybe<Scalars['String']>;
  geographyType?: Maybe<Scalars['String']>;
  intercept?: Maybe<Scalars['float8']>;
  observationCount?: Maybe<Scalars['float8']>;
  skills?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Skill_Pricing_By_Lad_Min_Fields = {
  codeLabel?: Maybe<Scalars['String']>;
  codeType?: Maybe<Scalars['String']>;
  coef?: Maybe<Scalars['float8']>;
  dateLabel?: Maybe<Scalars['Int']>;
  dateType?: Maybe<Scalars['String']>;
  geographyCode?: Maybe<Scalars['String']>;
  geographyLabel?: Maybe<Scalars['String']>;
  geographyType?: Maybe<Scalars['String']>;
  intercept?: Maybe<Scalars['float8']>;
  observationCount?: Maybe<Scalars['float8']>;
  skills?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "skill_pricing_by_lad". */
export type Skill_Pricing_By_Lad_Order_By = {
  codeLabel?: Maybe<Order_By>;
  codeType?: Maybe<Order_By>;
  coef?: Maybe<Order_By>;
  dateLabel?: Maybe<Order_By>;
  dateType?: Maybe<Order_By>;
  geographyCode?: Maybe<Order_By>;
  geographyLabel?: Maybe<Order_By>;
  geographyType?: Maybe<Order_By>;
  intercept?: Maybe<Order_By>;
  observationCount?: Maybe<Order_By>;
  skills?: Maybe<Order_By>;
};

/** select columns of table "skill_pricing_by_lad" */
export enum Skill_Pricing_By_Lad_Select_Column {
  /** column name */
  CodeLabel = 'codeLabel',
  /** column name */
  CodeType = 'codeType',
  /** column name */
  Coef = 'coef',
  /** column name */
  DateLabel = 'dateLabel',
  /** column name */
  DateType = 'dateType',
  /** column name */
  GeographyCode = 'geographyCode',
  /** column name */
  GeographyLabel = 'geographyLabel',
  /** column name */
  GeographyType = 'geographyType',
  /** column name */
  Intercept = 'intercept',
  /** column name */
  ObservationCount = 'observationCount',
  /** column name */
  Skills = 'skills'
}

/** aggregate stddev on columns */
export type Skill_Pricing_By_Lad_Stddev_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Skill_Pricing_By_Lad_Stddev_Pop_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Skill_Pricing_By_Lad_Stddev_Samp_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Skill_Pricing_By_Lad_Sum_Fields = {
  coef?: Maybe<Scalars['float8']>;
  dateLabel?: Maybe<Scalars['Int']>;
  intercept?: Maybe<Scalars['float8']>;
  observationCount?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Skill_Pricing_By_Lad_Var_Pop_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Skill_Pricing_By_Lad_Var_Samp_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Skill_Pricing_By_Lad_Variance_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "skill_pricing_sic" */
export type Skill_Pricing_Sic = {
  codeLabel?: Maybe<Scalars['String']>;
  codeType?: Maybe<Scalars['String']>;
  coef?: Maybe<Scalars['float8']>;
  /** An object relationship */
  counts?: Maybe<Skill_Counts>;
  dateLabel?: Maybe<Scalars['Int']>;
  dateType?: Maybe<Scalars['String']>;
  geographyLabel?: Maybe<Scalars['String']>;
  geographyType?: Maybe<Scalars['String']>;
  intercept?: Maybe<Scalars['float8']>;
  observationCount?: Maybe<Scalars['float8']>;
  skill?: Maybe<Scalars['String']>;
};

/** aggregated selection of "skill_pricing_sic" */
export type Skill_Pricing_Sic_Aggregate = {
  aggregate?: Maybe<Skill_Pricing_Sic_Aggregate_Fields>;
  nodes: Array<Skill_Pricing_Sic>;
};

/** aggregate fields of "skill_pricing_sic" */
export type Skill_Pricing_Sic_Aggregate_Fields = {
  avg?: Maybe<Skill_Pricing_Sic_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Skill_Pricing_Sic_Max_Fields>;
  min?: Maybe<Skill_Pricing_Sic_Min_Fields>;
  stddev?: Maybe<Skill_Pricing_Sic_Stddev_Fields>;
  stddev_pop?: Maybe<Skill_Pricing_Sic_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Skill_Pricing_Sic_Stddev_Samp_Fields>;
  sum?: Maybe<Skill_Pricing_Sic_Sum_Fields>;
  var_pop?: Maybe<Skill_Pricing_Sic_Var_Pop_Fields>;
  var_samp?: Maybe<Skill_Pricing_Sic_Var_Samp_Fields>;
  variance?: Maybe<Skill_Pricing_Sic_Variance_Fields>;
};


/** aggregate fields of "skill_pricing_sic" */
export type Skill_Pricing_Sic_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Skill_Pricing_Sic_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Skill_Pricing_Sic_Avg_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "skill_pricing_sic". All fields are combined with a logical 'AND'. */
export type Skill_Pricing_Sic_Bool_Exp = {
  _and?: Maybe<Array<Skill_Pricing_Sic_Bool_Exp>>;
  _not?: Maybe<Skill_Pricing_Sic_Bool_Exp>;
  _or?: Maybe<Array<Skill_Pricing_Sic_Bool_Exp>>;
  codeLabel?: Maybe<String_Comparison_Exp>;
  codeType?: Maybe<String_Comparison_Exp>;
  coef?: Maybe<Float8_Comparison_Exp>;
  counts?: Maybe<Skill_Counts_Bool_Exp>;
  dateLabel?: Maybe<Int_Comparison_Exp>;
  dateType?: Maybe<String_Comparison_Exp>;
  geographyLabel?: Maybe<String_Comparison_Exp>;
  geographyType?: Maybe<String_Comparison_Exp>;
  intercept?: Maybe<Float8_Comparison_Exp>;
  observationCount?: Maybe<Float8_Comparison_Exp>;
  skill?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Skill_Pricing_Sic_Max_Fields = {
  codeLabel?: Maybe<Scalars['String']>;
  codeType?: Maybe<Scalars['String']>;
  coef?: Maybe<Scalars['float8']>;
  dateLabel?: Maybe<Scalars['Int']>;
  dateType?: Maybe<Scalars['String']>;
  geographyLabel?: Maybe<Scalars['String']>;
  geographyType?: Maybe<Scalars['String']>;
  intercept?: Maybe<Scalars['float8']>;
  observationCount?: Maybe<Scalars['float8']>;
  skill?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Skill_Pricing_Sic_Min_Fields = {
  codeLabel?: Maybe<Scalars['String']>;
  codeType?: Maybe<Scalars['String']>;
  coef?: Maybe<Scalars['float8']>;
  dateLabel?: Maybe<Scalars['Int']>;
  dateType?: Maybe<Scalars['String']>;
  geographyLabel?: Maybe<Scalars['String']>;
  geographyType?: Maybe<Scalars['String']>;
  intercept?: Maybe<Scalars['float8']>;
  observationCount?: Maybe<Scalars['float8']>;
  skill?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "skill_pricing_sic". */
export type Skill_Pricing_Sic_Order_By = {
  codeLabel?: Maybe<Order_By>;
  codeType?: Maybe<Order_By>;
  coef?: Maybe<Order_By>;
  counts?: Maybe<Skill_Counts_Order_By>;
  dateLabel?: Maybe<Order_By>;
  dateType?: Maybe<Order_By>;
  geographyLabel?: Maybe<Order_By>;
  geographyType?: Maybe<Order_By>;
  intercept?: Maybe<Order_By>;
  observationCount?: Maybe<Order_By>;
  skill?: Maybe<Order_By>;
};

/** select columns of table "skill_pricing_sic" */
export enum Skill_Pricing_Sic_Select_Column {
  /** column name */
  CodeLabel = 'codeLabel',
  /** column name */
  CodeType = 'codeType',
  /** column name */
  Coef = 'coef',
  /** column name */
  DateLabel = 'dateLabel',
  /** column name */
  DateType = 'dateType',
  /** column name */
  GeographyLabel = 'geographyLabel',
  /** column name */
  GeographyType = 'geographyType',
  /** column name */
  Intercept = 'intercept',
  /** column name */
  ObservationCount = 'observationCount',
  /** column name */
  Skill = 'skill'
}

/** aggregate stddev on columns */
export type Skill_Pricing_Sic_Stddev_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Skill_Pricing_Sic_Stddev_Pop_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Skill_Pricing_Sic_Stddev_Samp_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Skill_Pricing_Sic_Sum_Fields = {
  coef?: Maybe<Scalars['float8']>;
  dateLabel?: Maybe<Scalars['Int']>;
  intercept?: Maybe<Scalars['float8']>;
  observationCount?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Skill_Pricing_Sic_Var_Pop_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Skill_Pricing_Sic_Var_Samp_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Skill_Pricing_Sic_Variance_Fields = {
  coef?: Maybe<Scalars['Float']>;
  dateLabel?: Maybe<Scalars['Float']>;
  intercept?: Maybe<Scalars['Float']>;
  observationCount?: Maybe<Scalars['Float']>;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: Maybe<Scalars['Boolean']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

export type Subscription_Root = {
  /** fetch data from the table: "lad" */
  lad: Array<Lad>;
  /** fetch aggregated fields from the table: "lad" */
  lad_aggregate: Lad_Aggregate;
  /** fetch data from the table: "salary_residuals_by_city" */
  salary_residuals_by_city: Array<Salary_Residuals_By_City>;
  /** fetch aggregated fields from the table: "salary_residuals_by_city" */
  salary_residuals_by_city_aggregate: Salary_Residuals_By_City_Aggregate;
  /** fetch data from the table: "salary_residuals_by_lad" */
  salary_residuals_by_lad: Array<Salary_Residuals_By_Lad>;
  /** fetch aggregated fields from the table: "salary_residuals_by_lad" */
  salary_residuals_by_lad_aggregate: Salary_Residuals_By_Lad_Aggregate;
  /** fetch data from the table: "skill_counts" */
  skill_counts: Array<Skill_Counts>;
  /** fetch aggregated fields from the table: "skill_counts" */
  skill_counts_aggregate: Skill_Counts_Aggregate;
  /** fetch data from the table: "skill_counts_by_sic" */
  skill_counts_by_sic: Array<Skill_Counts_By_Sic>;
  /** fetch aggregated fields from the table: "skill_counts_by_sic" */
  skill_counts_by_sic_aggregate: Skill_Counts_By_Sic_Aggregate;
  /** fetch data from the table: "skill_pricing_64_651_691" */
  skill_pricing_64_651_691: Array<Skill_Pricing_64_651_691>;
  /** fetch aggregated fields from the table: "skill_pricing_64_651_691" */
  skill_pricing_64_651_691_aggregate: Skill_Pricing_64_651_691_Aggregate;
  /** fetch data from the table: "skill_pricing_by_city" */
  skill_pricing_by_city: Array<Skill_Pricing_By_City>;
  /** fetch aggregated fields from the table: "skill_pricing_by_city" */
  skill_pricing_by_city_aggregate: Skill_Pricing_By_City_Aggregate;
  /** fetch data from the table: "skill_pricing_by_lad" */
  skill_pricing_by_lad: Array<Skill_Pricing_By_Lad>;
  /** fetch aggregated fields from the table: "skill_pricing_by_lad" */
  skill_pricing_by_lad_aggregate: Skill_Pricing_By_Lad_Aggregate;
  /** fetch data from the table: "skill_pricing_sic" */
  skill_pricing_sic: Array<Skill_Pricing_Sic>;
  /** fetch aggregated fields from the table: "skill_pricing_sic" */
  skill_pricing_sic_aggregate: Skill_Pricing_Sic_Aggregate;
  /** fetch data from the table: "tiles_salary_residuals_lad" */
  tiles_salary_residuals_lad: Array<Tiles_Salary_Residuals_Lad>;
  /** fetch aggregated fields from the table: "tiles_salary_residuals_lad" */
  tiles_salary_residuals_lad_aggregate: Tiles_Salary_Residuals_Lad_Aggregate;
};


export type Subscription_RootLadArgs = {
  distinct_on?: Maybe<Array<Lad_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lad_Order_By>>;
  where?: Maybe<Lad_Bool_Exp>;
};


export type Subscription_RootLad_AggregateArgs = {
  distinct_on?: Maybe<Array<Lad_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lad_Order_By>>;
  where?: Maybe<Lad_Bool_Exp>;
};


export type Subscription_RootSalary_Residuals_By_CityArgs = {
  distinct_on?: Maybe<Array<Salary_Residuals_By_City_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Salary_Residuals_By_City_Order_By>>;
  where?: Maybe<Salary_Residuals_By_City_Bool_Exp>;
};


export type Subscription_RootSalary_Residuals_By_City_AggregateArgs = {
  distinct_on?: Maybe<Array<Salary_Residuals_By_City_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Salary_Residuals_By_City_Order_By>>;
  where?: Maybe<Salary_Residuals_By_City_Bool_Exp>;
};


export type Subscription_RootSalary_Residuals_By_LadArgs = {
  distinct_on?: Maybe<Array<Salary_Residuals_By_Lad_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Salary_Residuals_By_Lad_Order_By>>;
  where?: Maybe<Salary_Residuals_By_Lad_Bool_Exp>;
};


export type Subscription_RootSalary_Residuals_By_Lad_AggregateArgs = {
  distinct_on?: Maybe<Array<Salary_Residuals_By_Lad_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Salary_Residuals_By_Lad_Order_By>>;
  where?: Maybe<Salary_Residuals_By_Lad_Bool_Exp>;
};


export type Subscription_RootSkill_CountsArgs = {
  distinct_on?: Maybe<Array<Skill_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Counts_Order_By>>;
  where?: Maybe<Skill_Counts_Bool_Exp>;
};


export type Subscription_RootSkill_Counts_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Counts_Order_By>>;
  where?: Maybe<Skill_Counts_Bool_Exp>;
};


export type Subscription_RootSkill_Counts_By_SicArgs = {
  distinct_on?: Maybe<Array<Skill_Counts_By_Sic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Counts_By_Sic_Order_By>>;
  where?: Maybe<Skill_Counts_By_Sic_Bool_Exp>;
};


export type Subscription_RootSkill_Counts_By_Sic_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Counts_By_Sic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Counts_By_Sic_Order_By>>;
  where?: Maybe<Skill_Counts_By_Sic_Bool_Exp>;
};


export type Subscription_RootSkill_Pricing_64_651_691Args = {
  distinct_on?: Maybe<Array<Skill_Pricing_64_651_691_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Pricing_64_651_691_Order_By>>;
  where?: Maybe<Skill_Pricing_64_651_691_Bool_Exp>;
};


export type Subscription_RootSkill_Pricing_64_651_691_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Pricing_64_651_691_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Pricing_64_651_691_Order_By>>;
  where?: Maybe<Skill_Pricing_64_651_691_Bool_Exp>;
};


export type Subscription_RootSkill_Pricing_By_CityArgs = {
  distinct_on?: Maybe<Array<Skill_Pricing_By_City_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Pricing_By_City_Order_By>>;
  where?: Maybe<Skill_Pricing_By_City_Bool_Exp>;
};


export type Subscription_RootSkill_Pricing_By_City_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Pricing_By_City_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Pricing_By_City_Order_By>>;
  where?: Maybe<Skill_Pricing_By_City_Bool_Exp>;
};


export type Subscription_RootSkill_Pricing_By_LadArgs = {
  distinct_on?: Maybe<Array<Skill_Pricing_By_Lad_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Pricing_By_Lad_Order_By>>;
  where?: Maybe<Skill_Pricing_By_Lad_Bool_Exp>;
};


export type Subscription_RootSkill_Pricing_By_Lad_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Pricing_By_Lad_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Pricing_By_Lad_Order_By>>;
  where?: Maybe<Skill_Pricing_By_Lad_Bool_Exp>;
};


export type Subscription_RootSkill_Pricing_SicArgs = {
  distinct_on?: Maybe<Array<Skill_Pricing_Sic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Pricing_Sic_Order_By>>;
  where?: Maybe<Skill_Pricing_Sic_Bool_Exp>;
};


export type Subscription_RootSkill_Pricing_Sic_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Pricing_Sic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Pricing_Sic_Order_By>>;
  where?: Maybe<Skill_Pricing_Sic_Bool_Exp>;
};


export type Subscription_RootTiles_Salary_Residuals_LadArgs = {
  distinct_on?: Maybe<Array<Tiles_Salary_Residuals_Lad_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tiles_Salary_Residuals_Lad_Order_By>>;
  where?: Maybe<Tiles_Salary_Residuals_Lad_Bool_Exp>;
};


export type Subscription_RootTiles_Salary_Residuals_Lad_AggregateArgs = {
  distinct_on?: Maybe<Array<Tiles_Salary_Residuals_Lad_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tiles_Salary_Residuals_Lad_Order_By>>;
  where?: Maybe<Tiles_Salary_Residuals_Lad_Bool_Exp>;
};

/** columns and relationships of "tiles_salary_residuals_lad" */
export type Tiles_Salary_Residuals_Lad = {
  id?: Maybe<Scalars['String']>;
  /** An object relationship */
  lad?: Maybe<Lad>;
  salary?: Maybe<Scalars['float8']>;
  salary_norm?: Maybe<Scalars['float8']>;
  sic?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "tiles_salary_residuals_lad" */
export type Tiles_Salary_Residuals_Lad_Aggregate = {
  aggregate?: Maybe<Tiles_Salary_Residuals_Lad_Aggregate_Fields>;
  nodes: Array<Tiles_Salary_Residuals_Lad>;
};

/** aggregate fields of "tiles_salary_residuals_lad" */
export type Tiles_Salary_Residuals_Lad_Aggregate_Fields = {
  avg?: Maybe<Tiles_Salary_Residuals_Lad_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tiles_Salary_Residuals_Lad_Max_Fields>;
  min?: Maybe<Tiles_Salary_Residuals_Lad_Min_Fields>;
  stddev?: Maybe<Tiles_Salary_Residuals_Lad_Stddev_Fields>;
  stddev_pop?: Maybe<Tiles_Salary_Residuals_Lad_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tiles_Salary_Residuals_Lad_Stddev_Samp_Fields>;
  sum?: Maybe<Tiles_Salary_Residuals_Lad_Sum_Fields>;
  var_pop?: Maybe<Tiles_Salary_Residuals_Lad_Var_Pop_Fields>;
  var_samp?: Maybe<Tiles_Salary_Residuals_Lad_Var_Samp_Fields>;
  variance?: Maybe<Tiles_Salary_Residuals_Lad_Variance_Fields>;
};


/** aggregate fields of "tiles_salary_residuals_lad" */
export type Tiles_Salary_Residuals_Lad_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tiles_Salary_Residuals_Lad_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tiles_Salary_Residuals_Lad_Avg_Fields = {
  salary?: Maybe<Scalars['Float']>;
  salary_norm?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tiles_salary_residuals_lad". All fields are combined with a logical 'AND'. */
export type Tiles_Salary_Residuals_Lad_Bool_Exp = {
  _and?: Maybe<Array<Tiles_Salary_Residuals_Lad_Bool_Exp>>;
  _not?: Maybe<Tiles_Salary_Residuals_Lad_Bool_Exp>;
  _or?: Maybe<Array<Tiles_Salary_Residuals_Lad_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
  lad?: Maybe<Lad_Bool_Exp>;
  salary?: Maybe<Float8_Comparison_Exp>;
  salary_norm?: Maybe<Float8_Comparison_Exp>;
  sic?: Maybe<String_Comparison_Exp>;
  year?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Tiles_Salary_Residuals_Lad_Max_Fields = {
  id?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['float8']>;
  salary_norm?: Maybe<Scalars['float8']>;
  sic?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Tiles_Salary_Residuals_Lad_Min_Fields = {
  id?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['float8']>;
  salary_norm?: Maybe<Scalars['float8']>;
  sic?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "tiles_salary_residuals_lad". */
export type Tiles_Salary_Residuals_Lad_Order_By = {
  id?: Maybe<Order_By>;
  lad?: Maybe<Lad_Order_By>;
  salary?: Maybe<Order_By>;
  salary_norm?: Maybe<Order_By>;
  sic?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** select columns of table "tiles_salary_residuals_lad" */
export enum Tiles_Salary_Residuals_Lad_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Salary = 'salary',
  /** column name */
  SalaryNorm = 'salary_norm',
  /** column name */
  Sic = 'sic',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type Tiles_Salary_Residuals_Lad_Stddev_Fields = {
  salary?: Maybe<Scalars['Float']>;
  salary_norm?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tiles_Salary_Residuals_Lad_Stddev_Pop_Fields = {
  salary?: Maybe<Scalars['Float']>;
  salary_norm?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tiles_Salary_Residuals_Lad_Stddev_Samp_Fields = {
  salary?: Maybe<Scalars['Float']>;
  salary_norm?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Tiles_Salary_Residuals_Lad_Sum_Fields = {
  salary?: Maybe<Scalars['float8']>;
  salary_norm?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Tiles_Salary_Residuals_Lad_Var_Pop_Fields = {
  salary?: Maybe<Scalars['Float']>;
  salary_norm?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tiles_Salary_Residuals_Lad_Var_Samp_Fields = {
  salary?: Maybe<Scalars['Float']>;
  salary_norm?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tiles_Salary_Residuals_Lad_Variance_Fields = {
  salary?: Maybe<Scalars['Float']>;
  salary_norm?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type CityDataSalariesQueryVariables = Exact<{
  year?: Scalars['Int'];
  sic?: Maybe<Scalars['String']>;
}>;


export type CityDataSalariesQuery = { salary_residuals_by_city: Array<{ city_code: Salary_Residuals_By_City['TCITY15CD'], salary: Salary_Residuals_By_City['loc_mean_salary'] }>, salary_residuals_by_city_aggregate: { aggregate?: Maybe<{ max?: Maybe<{ salary: Salary_Residuals_By_City_Max_Fields['loc_mean_salary'] }>, min?: Maybe<{ salary: Salary_Residuals_By_City_Min_Fields['loc_mean_salary'] }> }> } };

export type CityDataSkillsQueryVariables = Exact<{
  year?: Scalars['Int'];
  sic?: Maybe<Scalars['String']>;
  skill?: Maybe<Scalars['String']>;
}>;


export type CityDataSkillsQuery = { skill_pricing_by_city: Array<{ city_code: Skill_Pricing_By_City['TCITY15CD'], price: Skill_Pricing_By_City['coef'], count: Skill_Pricing_By_City['observationCount'] }>, skill_pricing_by_city_aggregate: { aggregate?: Maybe<{ max?: Maybe<{ price: Skill_Pricing_By_City_Max_Fields['coef'], count: Skill_Pricing_By_City_Max_Fields['observationCount'] }>, min?: Maybe<{ price: Skill_Pricing_By_City_Min_Fields['coef'], count: Skill_Pricing_By_City_Min_Fields['observationCount'] }> }> } };

export type LadDataSalariesQueryVariables = Exact<{
  year?: Scalars['float8'];
  sic?: Maybe<Scalars['String']>;
}>;


export type LadDataSalariesQuery = { salary_residuals_by_lad: Array<(
    Pick<Salary_Residuals_By_Lad, 'lad_code'>
    & { salary: Salary_Residuals_By_Lad['loc_mean_salary'] }
  )>, salary_residuals_by_lad_aggregate: { aggregate?: Maybe<{ max?: Maybe<{ salary: Salary_Residuals_By_Lad_Max_Fields['loc_mean_salary'] }>, min?: Maybe<{ salary: Salary_Residuals_By_Lad_Min_Fields['loc_mean_salary'] }> }> } };

export type LadDataSkillsQueryVariables = Exact<{
  year?: Scalars['Int'];
  sic?: Maybe<Scalars['String']>;
  skill?: Maybe<Scalars['String']>;
}>;


export type LadDataSkillsQuery = { skill_pricing_by_lad: Array<{ lad_code: Skill_Pricing_By_Lad['geographyCode'], price: Skill_Pricing_By_Lad['coef'], count: Skill_Pricing_By_Lad['observationCount'] }>, skill_pricing_by_lad_aggregate: { aggregate?: Maybe<{ max?: Maybe<{ price: Skill_Pricing_By_Lad_Max_Fields['coef'], count: Skill_Pricing_By_Lad_Max_Fields['observationCount'] }>, min?: Maybe<{ price: Skill_Pricing_By_Lad_Min_Fields['coef'], count: Skill_Pricing_By_Lad_Min_Fields['observationCount'] }> }> } };

export type DataLadQueryVariables = Exact<{
  sic?: Scalars['String'];
  lads?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type DataLadQuery = { salaries: Array<(
    Pick<Tiles_Salary_Residuals_Lad, 'id' | 'year' | 'salary'>
    & { lad?: Maybe<Pick<Lad, 'name'>> }
  )>, salaries_: { agg?: Maybe<{ min?: Maybe<Pick<Tiles_Salary_Residuals_Lad_Min_Fields, 'salary'>>, max?: Maybe<Pick<Tiles_Salary_Residuals_Lad_Max_Fields, 'salary'>> }> } };

export type DataQueryVariables = Exact<{
  sic?: Scalars['String'];
  skills?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type DataQuery = { counts: Array<(
    Pick<Skill_Pricing_Sic, 'skill'>
    & { year: Skill_Pricing_Sic['dateLabel'], price: Skill_Pricing_Sic['coef'] }
    & { counts?: Maybe<(
      Pick<Skill_Counts, 'proportion'>
      & { count: Skill_Counts['observationCount'] }
    )> }
  )>, counts_: { agg?: Maybe<{ min?: Maybe<{ price: Skill_Pricing_Sic_Min_Fields['coef'] }>, max?: Maybe<{ price: Skill_Pricing_Sic_Max_Fields['coef'] }> }> }, counts__: { agg?: Maybe<{ min?: Maybe<(
        Pick<Skill_Counts_Min_Fields, 'proportion'>
        & { count: Skill_Counts_Min_Fields['observationCount'] }
      )>, max?: Maybe<(
        Pick<Skill_Counts_Max_Fields, 'proportion'>
        & { count: Skill_Counts_Max_Fields['observationCount'] }
      )> }> } };


export const CityDataSalariesDocument = gql`
    query CityDataSalaries($year: Int! = 2021, $sic: String = "691") {
  salary_residuals_by_city(
    where: {_and: [{years: {_eq: $year}}, {SICCode: {_eq: $sic}}]}
  ) {
    city_code: TCITY15CD
    salary: loc_mean_salary
  }
  salary_residuals_by_city_aggregate(
    where: {_and: [{years: {_eq: $year}}, {SICCode: {_eq: $sic}}]}
  ) {
    aggregate {
      max {
        salary: loc_mean_salary
      }
      min {
        salary: loc_mean_salary
      }
    }
  }
}
    `;

/**
 * __useCityDataSalariesQuery__
 *
 * To run a query within a React component, call `useCityDataSalariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCityDataSalariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCityDataSalariesQuery({
 *   variables: {
 *      year: // value for 'year'
 *      sic: // value for 'sic'
 *   },
 * });
 */
export function useCityDataSalariesQuery(baseOptions?: Apollo.QueryHookOptions<CityDataSalariesQuery, CityDataSalariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CityDataSalariesQuery, CityDataSalariesQueryVariables>(CityDataSalariesDocument, options);
      }
export function useCityDataSalariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CityDataSalariesQuery, CityDataSalariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CityDataSalariesQuery, CityDataSalariesQueryVariables>(CityDataSalariesDocument, options);
        }
export type CityDataSalariesQueryHookResult = ReturnType<typeof useCityDataSalariesQuery>;
export type CityDataSalariesLazyQueryHookResult = ReturnType<typeof useCityDataSalariesLazyQuery>;
export type CityDataSalariesQueryResult = Apollo.QueryResult<CityDataSalariesQuery, CityDataSalariesQueryVariables>;
export const CityDataSkillsDocument = gql`
    query CityDataSkills($year: Int! = 2021, $sic: String = "691", $skill: String = "Commercial Litigation") {
  skill_pricing_by_city(
    where: {_and: [{dateLabel: {_eq: $year}}, {codeLabel: {_eq: $sic}}, {skills: {_eq: $skill}}]}
  ) {
    city_code: TCITY15CD
    price: coef
    count: observationCount
  }
  skill_pricing_by_city_aggregate(
    where: {_and: [{dateLabel: {_eq: $year}}, {codeLabel: {_eq: $sic}}]}
  ) {
    aggregate {
      max {
        price: coef
        count: observationCount
      }
      min {
        price: coef
        count: observationCount
      }
    }
  }
}
    `;

/**
 * __useCityDataSkillsQuery__
 *
 * To run a query within a React component, call `useCityDataSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCityDataSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCityDataSkillsQuery({
 *   variables: {
 *      year: // value for 'year'
 *      sic: // value for 'sic'
 *      skill: // value for 'skill'
 *   },
 * });
 */
export function useCityDataSkillsQuery(baseOptions?: Apollo.QueryHookOptions<CityDataSkillsQuery, CityDataSkillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CityDataSkillsQuery, CityDataSkillsQueryVariables>(CityDataSkillsDocument, options);
      }
export function useCityDataSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CityDataSkillsQuery, CityDataSkillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CityDataSkillsQuery, CityDataSkillsQueryVariables>(CityDataSkillsDocument, options);
        }
export type CityDataSkillsQueryHookResult = ReturnType<typeof useCityDataSkillsQuery>;
export type CityDataSkillsLazyQueryHookResult = ReturnType<typeof useCityDataSkillsLazyQuery>;
export type CityDataSkillsQueryResult = Apollo.QueryResult<CityDataSkillsQuery, CityDataSkillsQueryVariables>;
export const LadDataSalariesDocument = gql`
    query LadDataSalaries($year: float8! = 2021, $sic: String = "691") {
  salary_residuals_by_lad(
    where: {_and: [{years: {_eq: $year}}, {sic: {_eq: $sic}}]}
  ) {
    lad_code
    salary: loc_mean_salary
  }
  salary_residuals_by_lad_aggregate(
    where: {_and: [{years: {_eq: $year}}, {sic: {_eq: $sic}}]}
  ) {
    aggregate {
      max {
        salary: loc_mean_salary
      }
      min {
        salary: loc_mean_salary
      }
    }
  }
}
    `;

/**
 * __useLadDataSalariesQuery__
 *
 * To run a query within a React component, call `useLadDataSalariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLadDataSalariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLadDataSalariesQuery({
 *   variables: {
 *      year: // value for 'year'
 *      sic: // value for 'sic'
 *   },
 * });
 */
export function useLadDataSalariesQuery(baseOptions?: Apollo.QueryHookOptions<LadDataSalariesQuery, LadDataSalariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LadDataSalariesQuery, LadDataSalariesQueryVariables>(LadDataSalariesDocument, options);
      }
export function useLadDataSalariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LadDataSalariesQuery, LadDataSalariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LadDataSalariesQuery, LadDataSalariesQueryVariables>(LadDataSalariesDocument, options);
        }
export type LadDataSalariesQueryHookResult = ReturnType<typeof useLadDataSalariesQuery>;
export type LadDataSalariesLazyQueryHookResult = ReturnType<typeof useLadDataSalariesLazyQuery>;
export type LadDataSalariesQueryResult = Apollo.QueryResult<LadDataSalariesQuery, LadDataSalariesQueryVariables>;
export const LadDataSkillsDocument = gql`
    query LadDataSkills($year: Int! = 2021, $sic: String = "691", $skill: String = "Software Engineering") {
  skill_pricing_by_lad(
    where: {_and: [{dateLabel: {_eq: $year}}, {codeLabel: {_eq: $sic}}, {skills: {_eq: $skill}}]}
  ) {
    lad_code: geographyCode
    price: coef
    count: observationCount
  }
  skill_pricing_by_lad_aggregate(
    where: {_and: [{dateLabel: {_eq: $year}}, {codeLabel: {_eq: $sic}}]}
  ) {
    aggregate {
      max {
        price: coef
        count: observationCount
      }
      min {
        price: coef
        count: observationCount
      }
    }
  }
}
    `;

/**
 * __useLadDataSkillsQuery__
 *
 * To run a query within a React component, call `useLadDataSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLadDataSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLadDataSkillsQuery({
 *   variables: {
 *      year: // value for 'year'
 *      sic: // value for 'sic'
 *      skill: // value for 'skill'
 *   },
 * });
 */
export function useLadDataSkillsQuery(baseOptions?: Apollo.QueryHookOptions<LadDataSkillsQuery, LadDataSkillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LadDataSkillsQuery, LadDataSkillsQueryVariables>(LadDataSkillsDocument, options);
      }
export function useLadDataSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LadDataSkillsQuery, LadDataSkillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LadDataSkillsQuery, LadDataSkillsQueryVariables>(LadDataSkillsDocument, options);
        }
export type LadDataSkillsQueryHookResult = ReturnType<typeof useLadDataSkillsQuery>;
export type LadDataSkillsLazyQueryHookResult = ReturnType<typeof useLadDataSkillsLazyQuery>;
export type LadDataSkillsQueryResult = Apollo.QueryResult<LadDataSkillsQuery, LadDataSkillsQueryVariables>;
export const DataLadDocument = gql`
    query DataLad($sic: String! = "691", $lads: [String!] = ["City of London", "Kensington and Chelsea", "Hammersmith and Fulham", "Hackney", "Camden"]) {
  salaries: tiles_salary_residuals_lad(
    where: {_and: [{sic: {_eq: $sic}}, {lad: {name: {_in: $lads}}}]}
  ) {
    id
    year
    salary
    lad {
      name
    }
  }
  salaries_: tiles_salary_residuals_lad_aggregate(
    where: {_and: [{sic: {_eq: $sic}}, {lad: {name: {_in: $lads}}}]}
  ) {
    agg: aggregate {
      min {
        salary
      }
      max {
        salary
      }
    }
  }
}
    `;

/**
 * __useDataLadQuery__
 *
 * To run a query within a React component, call `useDataLadQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataLadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataLadQuery({
 *   variables: {
 *      sic: // value for 'sic'
 *      lads: // value for 'lads'
 *   },
 * });
 */
export function useDataLadQuery(baseOptions?: Apollo.QueryHookOptions<DataLadQuery, DataLadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataLadQuery, DataLadQueryVariables>(DataLadDocument, options);
      }
export function useDataLadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataLadQuery, DataLadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataLadQuery, DataLadQueryVariables>(DataLadDocument, options);
        }
export type DataLadQueryHookResult = ReturnType<typeof useDataLadQuery>;
export type DataLadLazyQueryHookResult = ReturnType<typeof useDataLadLazyQuery>;
export type DataLadQueryResult = Apollo.QueryResult<DataLadQuery, DataLadQueryVariables>;
export const DataDocument = gql`
    query Data($sic: String! = "691", $skills: [String!] = ["Software Engineering", "Python", "Commercial Litigation", "Contract Preparation", "Legal Support", "Legal Research"]) {
  counts: skill_pricing_sic(
    where: {_and: [{codeLabel: {_eq: $sic}}, {skill: {_in: $skills}}]}
  ) {
    skill
    year: dateLabel
    price: coef
    counts: counts {
      proportion
      count: observationCount
    }
  }
  counts_: skill_pricing_sic_aggregate(
    where: {_and: [{codeLabel: {_eq: $sic}}, {skill: {_in: $skills}}]}
  ) {
    agg: aggregate {
      min {
        price: coef
      }
      max {
        price: coef
      }
    }
  }
  counts__: skill_counts_aggregate(
    where: {_and: [{codeLabel: {_eq: $sic}}, {skill: {_in: $skills}}]}
  ) {
    agg: aggregate {
      min {
        proportion
        count: observationCount
      }
      max {
        proportion
        count: observationCount
      }
    }
  }
}
    `;

/**
 * __useDataQuery__
 *
 * To run a query within a React component, call `useDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataQuery({
 *   variables: {
 *      sic: // value for 'sic'
 *      skills: // value for 'skills'
 *   },
 * });
 */
export function useDataQuery(baseOptions?: Apollo.QueryHookOptions<DataQuery, DataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataQuery, DataQueryVariables>(DataDocument, options);
      }
export function useDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataQuery, DataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataQuery, DataQueryVariables>(DataDocument, options);
        }
export type DataQueryHookResult = ReturnType<typeof useDataQuery>;
export type DataLazyQueryHookResult = ReturnType<typeof useDataLazyQuery>;
export type DataQueryResult = Apollo.QueryResult<DataQuery, DataQueryVariables>;
export type ladKeySpecifier = ('geometry' | 'id' | 'name' | ladKeySpecifier)[];
export type ladFieldPolicy = {
	geometry?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type lad_aggregateKeySpecifier = ('aggregate' | 'nodes' | lad_aggregateKeySpecifier)[];
export type lad_aggregateFieldPolicy = {
	aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type lad_aggregate_fieldsKeySpecifier = ('count' | 'max' | 'min' | lad_aggregate_fieldsKeySpecifier)[];
export type lad_aggregate_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	max?: FieldPolicy<any> | FieldReadFunction<any>,
	min?: FieldPolicy<any> | FieldReadFunction<any>
};
export type lad_max_fieldsKeySpecifier = ('id' | 'name' | lad_max_fieldsKeySpecifier)[];
export type lad_max_fieldsFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type lad_min_fieldsKeySpecifier = ('id' | 'name' | lad_min_fieldsKeySpecifier)[];
export type lad_min_fieldsFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type query_rootKeySpecifier = ('lad' | 'lad_aggregate' | 'salary_residuals_by_city' | 'salary_residuals_by_city_aggregate' | 'salary_residuals_by_lad' | 'salary_residuals_by_lad_aggregate' | 'skill_counts' | 'skill_counts_aggregate' | 'skill_counts_by_sic' | 'skill_counts_by_sic_aggregate' | 'skill_pricing_64_651_691' | 'skill_pricing_64_651_691_aggregate' | 'skill_pricing_by_city' | 'skill_pricing_by_city_aggregate' | 'skill_pricing_by_lad' | 'skill_pricing_by_lad_aggregate' | 'skill_pricing_sic' | 'skill_pricing_sic_aggregate' | 'tiles_salary_residuals_lad' | 'tiles_salary_residuals_lad_aggregate' | query_rootKeySpecifier)[];
export type query_rootFieldPolicy = {
	lad?: FieldPolicy<any> | FieldReadFunction<any>,
	lad_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_residuals_by_city?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_residuals_by_city_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_residuals_by_lad?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_residuals_by_lad_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_counts?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_counts_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_counts_by_sic?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_counts_by_sic_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_pricing_64_651_691?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_pricing_64_651_691_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_pricing_by_city?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_pricing_by_city_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_pricing_by_lad?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_pricing_by_lad_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_pricing_sic?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_pricing_sic_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	tiles_salary_residuals_lad?: FieldPolicy<any> | FieldReadFunction<any>,
	tiles_salary_residuals_lad_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_cityKeySpecifier = ('CanonCity' | 'SICCode' | 'TCITY15CD' | 'loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_cityKeySpecifier)[];
export type salary_residuals_by_cityFieldPolicy = {
	CanonCity?: FieldPolicy<any> | FieldReadFunction<any>,
	SICCode?: FieldPolicy<any> | FieldReadFunction<any>,
	TCITY15CD?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_city_aggregateKeySpecifier = ('aggregate' | 'nodes' | salary_residuals_by_city_aggregateKeySpecifier)[];
export type salary_residuals_by_city_aggregateFieldPolicy = {
	aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_city_aggregate_fieldsKeySpecifier = ('avg' | 'count' | 'max' | 'min' | 'stddev' | 'stddev_pop' | 'stddev_samp' | 'sum' | 'var_pop' | 'var_samp' | 'variance' | salary_residuals_by_city_aggregate_fieldsKeySpecifier)[];
export type salary_residuals_by_city_aggregate_fieldsFieldPolicy = {
	avg?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	max?: FieldPolicy<any> | FieldReadFunction<any>,
	min?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	sum?: FieldPolicy<any> | FieldReadFunction<any>,
	var_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	var_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	variance?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_city_avg_fieldsKeySpecifier = ('loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_city_avg_fieldsKeySpecifier)[];
export type salary_residuals_by_city_avg_fieldsFieldPolicy = {
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_city_max_fieldsKeySpecifier = ('CanonCity' | 'SICCode' | 'TCITY15CD' | 'loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_city_max_fieldsKeySpecifier)[];
export type salary_residuals_by_city_max_fieldsFieldPolicy = {
	CanonCity?: FieldPolicy<any> | FieldReadFunction<any>,
	SICCode?: FieldPolicy<any> | FieldReadFunction<any>,
	TCITY15CD?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_city_min_fieldsKeySpecifier = ('CanonCity' | 'SICCode' | 'TCITY15CD' | 'loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_city_min_fieldsKeySpecifier)[];
export type salary_residuals_by_city_min_fieldsFieldPolicy = {
	CanonCity?: FieldPolicy<any> | FieldReadFunction<any>,
	SICCode?: FieldPolicy<any> | FieldReadFunction<any>,
	TCITY15CD?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_city_stddev_fieldsKeySpecifier = ('loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_city_stddev_fieldsKeySpecifier)[];
export type salary_residuals_by_city_stddev_fieldsFieldPolicy = {
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_city_stddev_pop_fieldsKeySpecifier = ('loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_city_stddev_pop_fieldsKeySpecifier)[];
export type salary_residuals_by_city_stddev_pop_fieldsFieldPolicy = {
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_city_stddev_samp_fieldsKeySpecifier = ('loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_city_stddev_samp_fieldsKeySpecifier)[];
export type salary_residuals_by_city_stddev_samp_fieldsFieldPolicy = {
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_city_sum_fieldsKeySpecifier = ('loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_city_sum_fieldsKeySpecifier)[];
export type salary_residuals_by_city_sum_fieldsFieldPolicy = {
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_city_var_pop_fieldsKeySpecifier = ('loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_city_var_pop_fieldsKeySpecifier)[];
export type salary_residuals_by_city_var_pop_fieldsFieldPolicy = {
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_city_var_samp_fieldsKeySpecifier = ('loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_city_var_samp_fieldsKeySpecifier)[];
export type salary_residuals_by_city_var_samp_fieldsFieldPolicy = {
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_city_variance_fieldsKeySpecifier = ('loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_city_variance_fieldsKeySpecifier)[];
export type salary_residuals_by_city_variance_fieldsFieldPolicy = {
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_ladKeySpecifier = ('lad_code' | 'lad_name' | 'loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'sic' | 'years' | salary_residuals_by_ladKeySpecifier)[];
export type salary_residuals_by_ladFieldPolicy = {
	lad_code?: FieldPolicy<any> | FieldReadFunction<any>,
	lad_name?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	sic?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_lad_aggregateKeySpecifier = ('aggregate' | 'nodes' | salary_residuals_by_lad_aggregateKeySpecifier)[];
export type salary_residuals_by_lad_aggregateFieldPolicy = {
	aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_lad_aggregate_fieldsKeySpecifier = ('avg' | 'count' | 'max' | 'min' | 'stddev' | 'stddev_pop' | 'stddev_samp' | 'sum' | 'var_pop' | 'var_samp' | 'variance' | salary_residuals_by_lad_aggregate_fieldsKeySpecifier)[];
export type salary_residuals_by_lad_aggregate_fieldsFieldPolicy = {
	avg?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	max?: FieldPolicy<any> | FieldReadFunction<any>,
	min?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	sum?: FieldPolicy<any> | FieldReadFunction<any>,
	var_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	var_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	variance?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_lad_avg_fieldsKeySpecifier = ('loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_lad_avg_fieldsKeySpecifier)[];
export type salary_residuals_by_lad_avg_fieldsFieldPolicy = {
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_lad_max_fieldsKeySpecifier = ('lad_code' | 'lad_name' | 'loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'sic' | 'years' | salary_residuals_by_lad_max_fieldsKeySpecifier)[];
export type salary_residuals_by_lad_max_fieldsFieldPolicy = {
	lad_code?: FieldPolicy<any> | FieldReadFunction<any>,
	lad_name?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	sic?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_lad_min_fieldsKeySpecifier = ('lad_code' | 'lad_name' | 'loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'sic' | 'years' | salary_residuals_by_lad_min_fieldsKeySpecifier)[];
export type salary_residuals_by_lad_min_fieldsFieldPolicy = {
	lad_code?: FieldPolicy<any> | FieldReadFunction<any>,
	lad_name?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	sic?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_lad_stddev_fieldsKeySpecifier = ('loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_lad_stddev_fieldsKeySpecifier)[];
export type salary_residuals_by_lad_stddev_fieldsFieldPolicy = {
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_lad_stddev_pop_fieldsKeySpecifier = ('loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_lad_stddev_pop_fieldsKeySpecifier)[];
export type salary_residuals_by_lad_stddev_pop_fieldsFieldPolicy = {
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_lad_stddev_samp_fieldsKeySpecifier = ('loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_lad_stddev_samp_fieldsKeySpecifier)[];
export type salary_residuals_by_lad_stddev_samp_fieldsFieldPolicy = {
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_lad_sum_fieldsKeySpecifier = ('loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_lad_sum_fieldsKeySpecifier)[];
export type salary_residuals_by_lad_sum_fieldsFieldPolicy = {
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_lad_var_pop_fieldsKeySpecifier = ('loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_lad_var_pop_fieldsKeySpecifier)[];
export type salary_residuals_by_lad_var_pop_fieldsFieldPolicy = {
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_lad_var_samp_fieldsKeySpecifier = ('loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_lad_var_samp_fieldsKeySpecifier)[];
export type salary_residuals_by_lad_var_samp_fieldsFieldPolicy = {
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type salary_residuals_by_lad_variance_fieldsKeySpecifier = ('loc_mean_salary' | 'loc_salary_sd' | 'n_ads' | 'r_mean' | 'r_sd' | 'years' | salary_residuals_by_lad_variance_fieldsKeySpecifier)[];
export type salary_residuals_by_lad_variance_fieldsFieldPolicy = {
	loc_mean_salary?: FieldPolicy<any> | FieldReadFunction<any>,
	loc_salary_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	n_ads?: FieldPolicy<any> | FieldReadFunction<any>,
	r_mean?: FieldPolicy<any> | FieldReadFunction<any>,
	r_sd?: FieldPolicy<any> | FieldReadFunction<any>,
	years?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_countsKeySpecifier = ('codeLabel' | 'codeType' | 'dateLabel' | 'dateType' | 'geographyLabel' | 'geographyType' | 'observationCount' | 'proportion' | 'skill' | skill_countsKeySpecifier)[];
export type skill_countsFieldPolicy = {
	codeLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	codeType?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	dateType?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyType?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	skill?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_aggregateKeySpecifier = ('aggregate' | 'nodes' | skill_counts_aggregateKeySpecifier)[];
export type skill_counts_aggregateFieldPolicy = {
	aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_aggregate_fieldsKeySpecifier = ('avg' | 'count' | 'max' | 'min' | 'stddev' | 'stddev_pop' | 'stddev_samp' | 'sum' | 'var_pop' | 'var_samp' | 'variance' | skill_counts_aggregate_fieldsKeySpecifier)[];
export type skill_counts_aggregate_fieldsFieldPolicy = {
	avg?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	max?: FieldPolicy<any> | FieldReadFunction<any>,
	min?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	sum?: FieldPolicy<any> | FieldReadFunction<any>,
	var_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	var_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	variance?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_avg_fieldsKeySpecifier = ('dateLabel' | 'observationCount' | 'proportion' | skill_counts_avg_fieldsKeySpecifier)[];
export type skill_counts_avg_fieldsFieldPolicy = {
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_by_sicKeySpecifier = ('count' | 'proportion' | 'sic' | 'year' | skill_counts_by_sicKeySpecifier)[];
export type skill_counts_by_sicFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	sic?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_by_sic_aggregateKeySpecifier = ('aggregate' | 'nodes' | skill_counts_by_sic_aggregateKeySpecifier)[];
export type skill_counts_by_sic_aggregateFieldPolicy = {
	aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_by_sic_aggregate_fieldsKeySpecifier = ('avg' | 'count' | 'max' | 'min' | 'stddev' | 'stddev_pop' | 'stddev_samp' | 'sum' | 'var_pop' | 'var_samp' | 'variance' | skill_counts_by_sic_aggregate_fieldsKeySpecifier)[];
export type skill_counts_by_sic_aggregate_fieldsFieldPolicy = {
	avg?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	max?: FieldPolicy<any> | FieldReadFunction<any>,
	min?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	sum?: FieldPolicy<any> | FieldReadFunction<any>,
	var_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	var_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	variance?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_by_sic_avg_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | skill_counts_by_sic_avg_fieldsKeySpecifier)[];
export type skill_counts_by_sic_avg_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_by_sic_max_fieldsKeySpecifier = ('count' | 'proportion' | 'sic' | 'year' | skill_counts_by_sic_max_fieldsKeySpecifier)[];
export type skill_counts_by_sic_max_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	sic?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_by_sic_min_fieldsKeySpecifier = ('count' | 'proportion' | 'sic' | 'year' | skill_counts_by_sic_min_fieldsKeySpecifier)[];
export type skill_counts_by_sic_min_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	sic?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_by_sic_stddev_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | skill_counts_by_sic_stddev_fieldsKeySpecifier)[];
export type skill_counts_by_sic_stddev_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_by_sic_stddev_pop_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | skill_counts_by_sic_stddev_pop_fieldsKeySpecifier)[];
export type skill_counts_by_sic_stddev_pop_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_by_sic_stddev_samp_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | skill_counts_by_sic_stddev_samp_fieldsKeySpecifier)[];
export type skill_counts_by_sic_stddev_samp_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_by_sic_sum_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | skill_counts_by_sic_sum_fieldsKeySpecifier)[];
export type skill_counts_by_sic_sum_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_by_sic_var_pop_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | skill_counts_by_sic_var_pop_fieldsKeySpecifier)[];
export type skill_counts_by_sic_var_pop_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_by_sic_var_samp_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | skill_counts_by_sic_var_samp_fieldsKeySpecifier)[];
export type skill_counts_by_sic_var_samp_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_by_sic_variance_fieldsKeySpecifier = ('count' | 'proportion' | 'year' | skill_counts_by_sic_variance_fieldsKeySpecifier)[];
export type skill_counts_by_sic_variance_fieldsFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_max_fieldsKeySpecifier = ('codeLabel' | 'codeType' | 'dateLabel' | 'dateType' | 'geographyLabel' | 'geographyType' | 'observationCount' | 'proportion' | 'skill' | skill_counts_max_fieldsKeySpecifier)[];
export type skill_counts_max_fieldsFieldPolicy = {
	codeLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	codeType?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	dateType?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyType?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	skill?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_min_fieldsKeySpecifier = ('codeLabel' | 'codeType' | 'dateLabel' | 'dateType' | 'geographyLabel' | 'geographyType' | 'observationCount' | 'proportion' | 'skill' | skill_counts_min_fieldsKeySpecifier)[];
export type skill_counts_min_fieldsFieldPolicy = {
	codeLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	codeType?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	dateType?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyType?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>,
	skill?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_stddev_fieldsKeySpecifier = ('dateLabel' | 'observationCount' | 'proportion' | skill_counts_stddev_fieldsKeySpecifier)[];
export type skill_counts_stddev_fieldsFieldPolicy = {
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_stddev_pop_fieldsKeySpecifier = ('dateLabel' | 'observationCount' | 'proportion' | skill_counts_stddev_pop_fieldsKeySpecifier)[];
export type skill_counts_stddev_pop_fieldsFieldPolicy = {
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_stddev_samp_fieldsKeySpecifier = ('dateLabel' | 'observationCount' | 'proportion' | skill_counts_stddev_samp_fieldsKeySpecifier)[];
export type skill_counts_stddev_samp_fieldsFieldPolicy = {
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_sum_fieldsKeySpecifier = ('dateLabel' | 'observationCount' | 'proportion' | skill_counts_sum_fieldsKeySpecifier)[];
export type skill_counts_sum_fieldsFieldPolicy = {
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_var_pop_fieldsKeySpecifier = ('dateLabel' | 'observationCount' | 'proportion' | skill_counts_var_pop_fieldsKeySpecifier)[];
export type skill_counts_var_pop_fieldsFieldPolicy = {
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_var_samp_fieldsKeySpecifier = ('dateLabel' | 'observationCount' | 'proportion' | skill_counts_var_samp_fieldsKeySpecifier)[];
export type skill_counts_var_samp_fieldsFieldPolicy = {
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_counts_variance_fieldsKeySpecifier = ('dateLabel' | 'observationCount' | 'proportion' | skill_counts_variance_fieldsKeySpecifier)[];
export type skill_counts_variance_fieldsFieldPolicy = {
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	proportion?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_64_651_691KeySpecifier = ('codeLabel' | 'codeType' | 'coef' | 'counts' | 'dateLabel' | 'dateType' | 'geographyLabel' | 'geographyType' | 'intercept' | 'observationCount' | 'skill' | skill_pricing_64_651_691KeySpecifier)[];
export type skill_pricing_64_651_691FieldPolicy = {
	codeLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	codeType?: FieldPolicy<any> | FieldReadFunction<any>,
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	counts?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	dateType?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyType?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	skill?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_64_651_691_aggregateKeySpecifier = ('aggregate' | 'nodes' | skill_pricing_64_651_691_aggregateKeySpecifier)[];
export type skill_pricing_64_651_691_aggregateFieldPolicy = {
	aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_64_651_691_aggregate_fieldsKeySpecifier = ('avg' | 'count' | 'max' | 'min' | 'stddev' | 'stddev_pop' | 'stddev_samp' | 'sum' | 'var_pop' | 'var_samp' | 'variance' | skill_pricing_64_651_691_aggregate_fieldsKeySpecifier)[];
export type skill_pricing_64_651_691_aggregate_fieldsFieldPolicy = {
	avg?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	max?: FieldPolicy<any> | FieldReadFunction<any>,
	min?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	sum?: FieldPolicy<any> | FieldReadFunction<any>,
	var_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	var_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	variance?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_64_651_691_avg_fieldsKeySpecifier = ('dateLabel' | 'intercept' | 'observationCount' | skill_pricing_64_651_691_avg_fieldsKeySpecifier)[];
export type skill_pricing_64_651_691_avg_fieldsFieldPolicy = {
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_64_651_691_max_fieldsKeySpecifier = ('codeLabel' | 'codeType' | 'coef' | 'dateLabel' | 'dateType' | 'geographyLabel' | 'geographyType' | 'intercept' | 'observationCount' | 'skill' | skill_pricing_64_651_691_max_fieldsKeySpecifier)[];
export type skill_pricing_64_651_691_max_fieldsFieldPolicy = {
	codeLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	codeType?: FieldPolicy<any> | FieldReadFunction<any>,
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	dateType?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyType?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	skill?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_64_651_691_min_fieldsKeySpecifier = ('codeLabel' | 'codeType' | 'coef' | 'dateLabel' | 'dateType' | 'geographyLabel' | 'geographyType' | 'intercept' | 'observationCount' | 'skill' | skill_pricing_64_651_691_min_fieldsKeySpecifier)[];
export type skill_pricing_64_651_691_min_fieldsFieldPolicy = {
	codeLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	codeType?: FieldPolicy<any> | FieldReadFunction<any>,
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	dateType?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyType?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	skill?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_64_651_691_stddev_fieldsKeySpecifier = ('dateLabel' | 'intercept' | 'observationCount' | skill_pricing_64_651_691_stddev_fieldsKeySpecifier)[];
export type skill_pricing_64_651_691_stddev_fieldsFieldPolicy = {
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_64_651_691_stddev_pop_fieldsKeySpecifier = ('dateLabel' | 'intercept' | 'observationCount' | skill_pricing_64_651_691_stddev_pop_fieldsKeySpecifier)[];
export type skill_pricing_64_651_691_stddev_pop_fieldsFieldPolicy = {
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_64_651_691_stddev_samp_fieldsKeySpecifier = ('dateLabel' | 'intercept' | 'observationCount' | skill_pricing_64_651_691_stddev_samp_fieldsKeySpecifier)[];
export type skill_pricing_64_651_691_stddev_samp_fieldsFieldPolicy = {
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_64_651_691_sum_fieldsKeySpecifier = ('dateLabel' | 'intercept' | 'observationCount' | skill_pricing_64_651_691_sum_fieldsKeySpecifier)[];
export type skill_pricing_64_651_691_sum_fieldsFieldPolicy = {
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_64_651_691_var_pop_fieldsKeySpecifier = ('dateLabel' | 'intercept' | 'observationCount' | skill_pricing_64_651_691_var_pop_fieldsKeySpecifier)[];
export type skill_pricing_64_651_691_var_pop_fieldsFieldPolicy = {
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_64_651_691_var_samp_fieldsKeySpecifier = ('dateLabel' | 'intercept' | 'observationCount' | skill_pricing_64_651_691_var_samp_fieldsKeySpecifier)[];
export type skill_pricing_64_651_691_var_samp_fieldsFieldPolicy = {
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_64_651_691_variance_fieldsKeySpecifier = ('dateLabel' | 'intercept' | 'observationCount' | skill_pricing_64_651_691_variance_fieldsKeySpecifier)[];
export type skill_pricing_64_651_691_variance_fieldsFieldPolicy = {
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_cityKeySpecifier = ('TCITY15CD' | 'codeLabel' | 'codeType' | 'coef' | 'dateLabel' | 'dateType' | 'geographyLabel' | 'geographyType' | 'intercept' | 'observationCount' | 'skills' | skill_pricing_by_cityKeySpecifier)[];
export type skill_pricing_by_cityFieldPolicy = {
	TCITY15CD?: FieldPolicy<any> | FieldReadFunction<any>,
	codeLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	codeType?: FieldPolicy<any> | FieldReadFunction<any>,
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	dateType?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyType?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	skills?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_city_aggregateKeySpecifier = ('aggregate' | 'nodes' | skill_pricing_by_city_aggregateKeySpecifier)[];
export type skill_pricing_by_city_aggregateFieldPolicy = {
	aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_city_aggregate_fieldsKeySpecifier = ('avg' | 'count' | 'max' | 'min' | 'stddev' | 'stddev_pop' | 'stddev_samp' | 'sum' | 'var_pop' | 'var_samp' | 'variance' | skill_pricing_by_city_aggregate_fieldsKeySpecifier)[];
export type skill_pricing_by_city_aggregate_fieldsFieldPolicy = {
	avg?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	max?: FieldPolicy<any> | FieldReadFunction<any>,
	min?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	sum?: FieldPolicy<any> | FieldReadFunction<any>,
	var_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	var_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	variance?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_city_avg_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_by_city_avg_fieldsKeySpecifier)[];
export type skill_pricing_by_city_avg_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_city_max_fieldsKeySpecifier = ('TCITY15CD' | 'codeLabel' | 'codeType' | 'coef' | 'dateLabel' | 'dateType' | 'geographyLabel' | 'geographyType' | 'intercept' | 'observationCount' | 'skills' | skill_pricing_by_city_max_fieldsKeySpecifier)[];
export type skill_pricing_by_city_max_fieldsFieldPolicy = {
	TCITY15CD?: FieldPolicy<any> | FieldReadFunction<any>,
	codeLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	codeType?: FieldPolicy<any> | FieldReadFunction<any>,
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	dateType?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyType?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	skills?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_city_min_fieldsKeySpecifier = ('TCITY15CD' | 'codeLabel' | 'codeType' | 'coef' | 'dateLabel' | 'dateType' | 'geographyLabel' | 'geographyType' | 'intercept' | 'observationCount' | 'skills' | skill_pricing_by_city_min_fieldsKeySpecifier)[];
export type skill_pricing_by_city_min_fieldsFieldPolicy = {
	TCITY15CD?: FieldPolicy<any> | FieldReadFunction<any>,
	codeLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	codeType?: FieldPolicy<any> | FieldReadFunction<any>,
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	dateType?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyType?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	skills?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_city_stddev_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_by_city_stddev_fieldsKeySpecifier)[];
export type skill_pricing_by_city_stddev_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_city_stddev_pop_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_by_city_stddev_pop_fieldsKeySpecifier)[];
export type skill_pricing_by_city_stddev_pop_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_city_stddev_samp_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_by_city_stddev_samp_fieldsKeySpecifier)[];
export type skill_pricing_by_city_stddev_samp_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_city_sum_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_by_city_sum_fieldsKeySpecifier)[];
export type skill_pricing_by_city_sum_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_city_var_pop_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_by_city_var_pop_fieldsKeySpecifier)[];
export type skill_pricing_by_city_var_pop_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_city_var_samp_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_by_city_var_samp_fieldsKeySpecifier)[];
export type skill_pricing_by_city_var_samp_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_city_variance_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_by_city_variance_fieldsKeySpecifier)[];
export type skill_pricing_by_city_variance_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_ladKeySpecifier = ('codeLabel' | 'codeType' | 'coef' | 'dateLabel' | 'dateType' | 'geographyCode' | 'geographyLabel' | 'geographyType' | 'intercept' | 'observationCount' | 'skills' | skill_pricing_by_ladKeySpecifier)[];
export type skill_pricing_by_ladFieldPolicy = {
	codeLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	codeType?: FieldPolicy<any> | FieldReadFunction<any>,
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	dateType?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyCode?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyType?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	skills?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_lad_aggregateKeySpecifier = ('aggregate' | 'nodes' | skill_pricing_by_lad_aggregateKeySpecifier)[];
export type skill_pricing_by_lad_aggregateFieldPolicy = {
	aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_lad_aggregate_fieldsKeySpecifier = ('avg' | 'count' | 'max' | 'min' | 'stddev' | 'stddev_pop' | 'stddev_samp' | 'sum' | 'var_pop' | 'var_samp' | 'variance' | skill_pricing_by_lad_aggregate_fieldsKeySpecifier)[];
export type skill_pricing_by_lad_aggregate_fieldsFieldPolicy = {
	avg?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	max?: FieldPolicy<any> | FieldReadFunction<any>,
	min?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	sum?: FieldPolicy<any> | FieldReadFunction<any>,
	var_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	var_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	variance?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_lad_avg_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_by_lad_avg_fieldsKeySpecifier)[];
export type skill_pricing_by_lad_avg_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_lad_max_fieldsKeySpecifier = ('codeLabel' | 'codeType' | 'coef' | 'dateLabel' | 'dateType' | 'geographyCode' | 'geographyLabel' | 'geographyType' | 'intercept' | 'observationCount' | 'skills' | skill_pricing_by_lad_max_fieldsKeySpecifier)[];
export type skill_pricing_by_lad_max_fieldsFieldPolicy = {
	codeLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	codeType?: FieldPolicy<any> | FieldReadFunction<any>,
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	dateType?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyCode?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyType?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	skills?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_lad_min_fieldsKeySpecifier = ('codeLabel' | 'codeType' | 'coef' | 'dateLabel' | 'dateType' | 'geographyCode' | 'geographyLabel' | 'geographyType' | 'intercept' | 'observationCount' | 'skills' | skill_pricing_by_lad_min_fieldsKeySpecifier)[];
export type skill_pricing_by_lad_min_fieldsFieldPolicy = {
	codeLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	codeType?: FieldPolicy<any> | FieldReadFunction<any>,
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	dateType?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyCode?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyType?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	skills?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_lad_stddev_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_by_lad_stddev_fieldsKeySpecifier)[];
export type skill_pricing_by_lad_stddev_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_lad_stddev_pop_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_by_lad_stddev_pop_fieldsKeySpecifier)[];
export type skill_pricing_by_lad_stddev_pop_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_lad_stddev_samp_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_by_lad_stddev_samp_fieldsKeySpecifier)[];
export type skill_pricing_by_lad_stddev_samp_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_lad_sum_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_by_lad_sum_fieldsKeySpecifier)[];
export type skill_pricing_by_lad_sum_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_lad_var_pop_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_by_lad_var_pop_fieldsKeySpecifier)[];
export type skill_pricing_by_lad_var_pop_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_lad_var_samp_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_by_lad_var_samp_fieldsKeySpecifier)[];
export type skill_pricing_by_lad_var_samp_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_by_lad_variance_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_by_lad_variance_fieldsKeySpecifier)[];
export type skill_pricing_by_lad_variance_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_sicKeySpecifier = ('codeLabel' | 'codeType' | 'coef' | 'counts' | 'dateLabel' | 'dateType' | 'geographyLabel' | 'geographyType' | 'intercept' | 'observationCount' | 'skill' | skill_pricing_sicKeySpecifier)[];
export type skill_pricing_sicFieldPolicy = {
	codeLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	codeType?: FieldPolicy<any> | FieldReadFunction<any>,
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	counts?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	dateType?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyType?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	skill?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_sic_aggregateKeySpecifier = ('aggregate' | 'nodes' | skill_pricing_sic_aggregateKeySpecifier)[];
export type skill_pricing_sic_aggregateFieldPolicy = {
	aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_sic_aggregate_fieldsKeySpecifier = ('avg' | 'count' | 'max' | 'min' | 'stddev' | 'stddev_pop' | 'stddev_samp' | 'sum' | 'var_pop' | 'var_samp' | 'variance' | skill_pricing_sic_aggregate_fieldsKeySpecifier)[];
export type skill_pricing_sic_aggregate_fieldsFieldPolicy = {
	avg?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	max?: FieldPolicy<any> | FieldReadFunction<any>,
	min?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	sum?: FieldPolicy<any> | FieldReadFunction<any>,
	var_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	var_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	variance?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_sic_avg_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_sic_avg_fieldsKeySpecifier)[];
export type skill_pricing_sic_avg_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_sic_max_fieldsKeySpecifier = ('codeLabel' | 'codeType' | 'coef' | 'dateLabel' | 'dateType' | 'geographyLabel' | 'geographyType' | 'intercept' | 'observationCount' | 'skill' | skill_pricing_sic_max_fieldsKeySpecifier)[];
export type skill_pricing_sic_max_fieldsFieldPolicy = {
	codeLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	codeType?: FieldPolicy<any> | FieldReadFunction<any>,
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	dateType?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyType?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	skill?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_sic_min_fieldsKeySpecifier = ('codeLabel' | 'codeType' | 'coef' | 'dateLabel' | 'dateType' | 'geographyLabel' | 'geographyType' | 'intercept' | 'observationCount' | 'skill' | skill_pricing_sic_min_fieldsKeySpecifier)[];
export type skill_pricing_sic_min_fieldsFieldPolicy = {
	codeLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	codeType?: FieldPolicy<any> | FieldReadFunction<any>,
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	dateType?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	geographyType?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>,
	skill?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_sic_stddev_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_sic_stddev_fieldsKeySpecifier)[];
export type skill_pricing_sic_stddev_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_sic_stddev_pop_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_sic_stddev_pop_fieldsKeySpecifier)[];
export type skill_pricing_sic_stddev_pop_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_sic_stddev_samp_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_sic_stddev_samp_fieldsKeySpecifier)[];
export type skill_pricing_sic_stddev_samp_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_sic_sum_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_sic_sum_fieldsKeySpecifier)[];
export type skill_pricing_sic_sum_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_sic_var_pop_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_sic_var_pop_fieldsKeySpecifier)[];
export type skill_pricing_sic_var_pop_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_sic_var_samp_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_sic_var_samp_fieldsKeySpecifier)[];
export type skill_pricing_sic_var_samp_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type skill_pricing_sic_variance_fieldsKeySpecifier = ('coef' | 'dateLabel' | 'intercept' | 'observationCount' | skill_pricing_sic_variance_fieldsKeySpecifier)[];
export type skill_pricing_sic_variance_fieldsFieldPolicy = {
	coef?: FieldPolicy<any> | FieldReadFunction<any>,
	dateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	intercept?: FieldPolicy<any> | FieldReadFunction<any>,
	observationCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type subscription_rootKeySpecifier = ('lad' | 'lad_aggregate' | 'salary_residuals_by_city' | 'salary_residuals_by_city_aggregate' | 'salary_residuals_by_lad' | 'salary_residuals_by_lad_aggregate' | 'skill_counts' | 'skill_counts_aggregate' | 'skill_counts_by_sic' | 'skill_counts_by_sic_aggregate' | 'skill_pricing_64_651_691' | 'skill_pricing_64_651_691_aggregate' | 'skill_pricing_by_city' | 'skill_pricing_by_city_aggregate' | 'skill_pricing_by_lad' | 'skill_pricing_by_lad_aggregate' | 'skill_pricing_sic' | 'skill_pricing_sic_aggregate' | 'tiles_salary_residuals_lad' | 'tiles_salary_residuals_lad_aggregate' | subscription_rootKeySpecifier)[];
export type subscription_rootFieldPolicy = {
	lad?: FieldPolicy<any> | FieldReadFunction<any>,
	lad_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_residuals_by_city?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_residuals_by_city_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_residuals_by_lad?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_residuals_by_lad_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_counts?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_counts_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_counts_by_sic?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_counts_by_sic_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_pricing_64_651_691?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_pricing_64_651_691_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_pricing_by_city?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_pricing_by_city_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_pricing_by_lad?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_pricing_by_lad_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_pricing_sic?: FieldPolicy<any> | FieldReadFunction<any>,
	skill_pricing_sic_aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	tiles_salary_residuals_lad?: FieldPolicy<any> | FieldReadFunction<any>,
	tiles_salary_residuals_lad_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
};
export type tiles_salary_residuals_ladKeySpecifier = ('id' | 'lad' | 'salary' | 'salary_norm' | 'sic' | 'year' | tiles_salary_residuals_ladKeySpecifier)[];
export type tiles_salary_residuals_ladFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	lad?: FieldPolicy<any> | FieldReadFunction<any>,
	salary?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_norm?: FieldPolicy<any> | FieldReadFunction<any>,
	sic?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type tiles_salary_residuals_lad_aggregateKeySpecifier = ('aggregate' | 'nodes' | tiles_salary_residuals_lad_aggregateKeySpecifier)[];
export type tiles_salary_residuals_lad_aggregateFieldPolicy = {
	aggregate?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type tiles_salary_residuals_lad_aggregate_fieldsKeySpecifier = ('avg' | 'count' | 'max' | 'min' | 'stddev' | 'stddev_pop' | 'stddev_samp' | 'sum' | 'var_pop' | 'var_samp' | 'variance' | tiles_salary_residuals_lad_aggregate_fieldsKeySpecifier)[];
export type tiles_salary_residuals_lad_aggregate_fieldsFieldPolicy = {
	avg?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	max?: FieldPolicy<any> | FieldReadFunction<any>,
	min?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	sum?: FieldPolicy<any> | FieldReadFunction<any>,
	var_pop?: FieldPolicy<any> | FieldReadFunction<any>,
	var_samp?: FieldPolicy<any> | FieldReadFunction<any>,
	variance?: FieldPolicy<any> | FieldReadFunction<any>
};
export type tiles_salary_residuals_lad_avg_fieldsKeySpecifier = ('salary' | 'salary_norm' | 'year' | tiles_salary_residuals_lad_avg_fieldsKeySpecifier)[];
export type tiles_salary_residuals_lad_avg_fieldsFieldPolicy = {
	salary?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_norm?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type tiles_salary_residuals_lad_max_fieldsKeySpecifier = ('id' | 'salary' | 'salary_norm' | 'sic' | 'year' | tiles_salary_residuals_lad_max_fieldsKeySpecifier)[];
export type tiles_salary_residuals_lad_max_fieldsFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	salary?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_norm?: FieldPolicy<any> | FieldReadFunction<any>,
	sic?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type tiles_salary_residuals_lad_min_fieldsKeySpecifier = ('id' | 'salary' | 'salary_norm' | 'sic' | 'year' | tiles_salary_residuals_lad_min_fieldsKeySpecifier)[];
export type tiles_salary_residuals_lad_min_fieldsFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	salary?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_norm?: FieldPolicy<any> | FieldReadFunction<any>,
	sic?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type tiles_salary_residuals_lad_stddev_fieldsKeySpecifier = ('salary' | 'salary_norm' | 'year' | tiles_salary_residuals_lad_stddev_fieldsKeySpecifier)[];
export type tiles_salary_residuals_lad_stddev_fieldsFieldPolicy = {
	salary?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_norm?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type tiles_salary_residuals_lad_stddev_pop_fieldsKeySpecifier = ('salary' | 'salary_norm' | 'year' | tiles_salary_residuals_lad_stddev_pop_fieldsKeySpecifier)[];
export type tiles_salary_residuals_lad_stddev_pop_fieldsFieldPolicy = {
	salary?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_norm?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type tiles_salary_residuals_lad_stddev_samp_fieldsKeySpecifier = ('salary' | 'salary_norm' | 'year' | tiles_salary_residuals_lad_stddev_samp_fieldsKeySpecifier)[];
export type tiles_salary_residuals_lad_stddev_samp_fieldsFieldPolicy = {
	salary?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_norm?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type tiles_salary_residuals_lad_sum_fieldsKeySpecifier = ('salary' | 'salary_norm' | 'year' | tiles_salary_residuals_lad_sum_fieldsKeySpecifier)[];
export type tiles_salary_residuals_lad_sum_fieldsFieldPolicy = {
	salary?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_norm?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type tiles_salary_residuals_lad_var_pop_fieldsKeySpecifier = ('salary' | 'salary_norm' | 'year' | tiles_salary_residuals_lad_var_pop_fieldsKeySpecifier)[];
export type tiles_salary_residuals_lad_var_pop_fieldsFieldPolicy = {
	salary?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_norm?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type tiles_salary_residuals_lad_var_samp_fieldsKeySpecifier = ('salary' | 'salary_norm' | 'year' | tiles_salary_residuals_lad_var_samp_fieldsKeySpecifier)[];
export type tiles_salary_residuals_lad_var_samp_fieldsFieldPolicy = {
	salary?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_norm?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type tiles_salary_residuals_lad_variance_fieldsKeySpecifier = ('salary' | 'salary_norm' | 'year' | tiles_salary_residuals_lad_variance_fieldsKeySpecifier)[];
export type tiles_salary_residuals_lad_variance_fieldsFieldPolicy = {
	salary?: FieldPolicy<any> | FieldReadFunction<any>,
	salary_norm?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TypedTypePolicies = TypePolicies & {
	lad?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ladKeySpecifier | (() => undefined | ladKeySpecifier),
		fields?: ladFieldPolicy,
	},
	lad_aggregate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | lad_aggregateKeySpecifier | (() => undefined | lad_aggregateKeySpecifier),
		fields?: lad_aggregateFieldPolicy,
	},
	lad_aggregate_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | lad_aggregate_fieldsKeySpecifier | (() => undefined | lad_aggregate_fieldsKeySpecifier),
		fields?: lad_aggregate_fieldsFieldPolicy,
	},
	lad_max_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | lad_max_fieldsKeySpecifier | (() => undefined | lad_max_fieldsKeySpecifier),
		fields?: lad_max_fieldsFieldPolicy,
	},
	lad_min_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | lad_min_fieldsKeySpecifier | (() => undefined | lad_min_fieldsKeySpecifier),
		fields?: lad_min_fieldsFieldPolicy,
	},
	query_root?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | query_rootKeySpecifier | (() => undefined | query_rootKeySpecifier),
		fields?: query_rootFieldPolicy,
	},
	salary_residuals_by_city?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_cityKeySpecifier | (() => undefined | salary_residuals_by_cityKeySpecifier),
		fields?: salary_residuals_by_cityFieldPolicy,
	},
	salary_residuals_by_city_aggregate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_city_aggregateKeySpecifier | (() => undefined | salary_residuals_by_city_aggregateKeySpecifier),
		fields?: salary_residuals_by_city_aggregateFieldPolicy,
	},
	salary_residuals_by_city_aggregate_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_city_aggregate_fieldsKeySpecifier | (() => undefined | salary_residuals_by_city_aggregate_fieldsKeySpecifier),
		fields?: salary_residuals_by_city_aggregate_fieldsFieldPolicy,
	},
	salary_residuals_by_city_avg_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_city_avg_fieldsKeySpecifier | (() => undefined | salary_residuals_by_city_avg_fieldsKeySpecifier),
		fields?: salary_residuals_by_city_avg_fieldsFieldPolicy,
	},
	salary_residuals_by_city_max_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_city_max_fieldsKeySpecifier | (() => undefined | salary_residuals_by_city_max_fieldsKeySpecifier),
		fields?: salary_residuals_by_city_max_fieldsFieldPolicy,
	},
	salary_residuals_by_city_min_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_city_min_fieldsKeySpecifier | (() => undefined | salary_residuals_by_city_min_fieldsKeySpecifier),
		fields?: salary_residuals_by_city_min_fieldsFieldPolicy,
	},
	salary_residuals_by_city_stddev_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_city_stddev_fieldsKeySpecifier | (() => undefined | salary_residuals_by_city_stddev_fieldsKeySpecifier),
		fields?: salary_residuals_by_city_stddev_fieldsFieldPolicy,
	},
	salary_residuals_by_city_stddev_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_city_stddev_pop_fieldsKeySpecifier | (() => undefined | salary_residuals_by_city_stddev_pop_fieldsKeySpecifier),
		fields?: salary_residuals_by_city_stddev_pop_fieldsFieldPolicy,
	},
	salary_residuals_by_city_stddev_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_city_stddev_samp_fieldsKeySpecifier | (() => undefined | salary_residuals_by_city_stddev_samp_fieldsKeySpecifier),
		fields?: salary_residuals_by_city_stddev_samp_fieldsFieldPolicy,
	},
	salary_residuals_by_city_sum_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_city_sum_fieldsKeySpecifier | (() => undefined | salary_residuals_by_city_sum_fieldsKeySpecifier),
		fields?: salary_residuals_by_city_sum_fieldsFieldPolicy,
	},
	salary_residuals_by_city_var_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_city_var_pop_fieldsKeySpecifier | (() => undefined | salary_residuals_by_city_var_pop_fieldsKeySpecifier),
		fields?: salary_residuals_by_city_var_pop_fieldsFieldPolicy,
	},
	salary_residuals_by_city_var_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_city_var_samp_fieldsKeySpecifier | (() => undefined | salary_residuals_by_city_var_samp_fieldsKeySpecifier),
		fields?: salary_residuals_by_city_var_samp_fieldsFieldPolicy,
	},
	salary_residuals_by_city_variance_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_city_variance_fieldsKeySpecifier | (() => undefined | salary_residuals_by_city_variance_fieldsKeySpecifier),
		fields?: salary_residuals_by_city_variance_fieldsFieldPolicy,
	},
	salary_residuals_by_lad?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_ladKeySpecifier | (() => undefined | salary_residuals_by_ladKeySpecifier),
		fields?: salary_residuals_by_ladFieldPolicy,
	},
	salary_residuals_by_lad_aggregate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_lad_aggregateKeySpecifier | (() => undefined | salary_residuals_by_lad_aggregateKeySpecifier),
		fields?: salary_residuals_by_lad_aggregateFieldPolicy,
	},
	salary_residuals_by_lad_aggregate_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_lad_aggregate_fieldsKeySpecifier | (() => undefined | salary_residuals_by_lad_aggregate_fieldsKeySpecifier),
		fields?: salary_residuals_by_lad_aggregate_fieldsFieldPolicy,
	},
	salary_residuals_by_lad_avg_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_lad_avg_fieldsKeySpecifier | (() => undefined | salary_residuals_by_lad_avg_fieldsKeySpecifier),
		fields?: salary_residuals_by_lad_avg_fieldsFieldPolicy,
	},
	salary_residuals_by_lad_max_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_lad_max_fieldsKeySpecifier | (() => undefined | salary_residuals_by_lad_max_fieldsKeySpecifier),
		fields?: salary_residuals_by_lad_max_fieldsFieldPolicy,
	},
	salary_residuals_by_lad_min_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_lad_min_fieldsKeySpecifier | (() => undefined | salary_residuals_by_lad_min_fieldsKeySpecifier),
		fields?: salary_residuals_by_lad_min_fieldsFieldPolicy,
	},
	salary_residuals_by_lad_stddev_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_lad_stddev_fieldsKeySpecifier | (() => undefined | salary_residuals_by_lad_stddev_fieldsKeySpecifier),
		fields?: salary_residuals_by_lad_stddev_fieldsFieldPolicy,
	},
	salary_residuals_by_lad_stddev_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_lad_stddev_pop_fieldsKeySpecifier | (() => undefined | salary_residuals_by_lad_stddev_pop_fieldsKeySpecifier),
		fields?: salary_residuals_by_lad_stddev_pop_fieldsFieldPolicy,
	},
	salary_residuals_by_lad_stddev_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_lad_stddev_samp_fieldsKeySpecifier | (() => undefined | salary_residuals_by_lad_stddev_samp_fieldsKeySpecifier),
		fields?: salary_residuals_by_lad_stddev_samp_fieldsFieldPolicy,
	},
	salary_residuals_by_lad_sum_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_lad_sum_fieldsKeySpecifier | (() => undefined | salary_residuals_by_lad_sum_fieldsKeySpecifier),
		fields?: salary_residuals_by_lad_sum_fieldsFieldPolicy,
	},
	salary_residuals_by_lad_var_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_lad_var_pop_fieldsKeySpecifier | (() => undefined | salary_residuals_by_lad_var_pop_fieldsKeySpecifier),
		fields?: salary_residuals_by_lad_var_pop_fieldsFieldPolicy,
	},
	salary_residuals_by_lad_var_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_lad_var_samp_fieldsKeySpecifier | (() => undefined | salary_residuals_by_lad_var_samp_fieldsKeySpecifier),
		fields?: salary_residuals_by_lad_var_samp_fieldsFieldPolicy,
	},
	salary_residuals_by_lad_variance_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | salary_residuals_by_lad_variance_fieldsKeySpecifier | (() => undefined | salary_residuals_by_lad_variance_fieldsKeySpecifier),
		fields?: salary_residuals_by_lad_variance_fieldsFieldPolicy,
	},
	skill_counts?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_countsKeySpecifier | (() => undefined | skill_countsKeySpecifier),
		fields?: skill_countsFieldPolicy,
	},
	skill_counts_aggregate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_aggregateKeySpecifier | (() => undefined | skill_counts_aggregateKeySpecifier),
		fields?: skill_counts_aggregateFieldPolicy,
	},
	skill_counts_aggregate_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_aggregate_fieldsKeySpecifier | (() => undefined | skill_counts_aggregate_fieldsKeySpecifier),
		fields?: skill_counts_aggregate_fieldsFieldPolicy,
	},
	skill_counts_avg_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_avg_fieldsKeySpecifier | (() => undefined | skill_counts_avg_fieldsKeySpecifier),
		fields?: skill_counts_avg_fieldsFieldPolicy,
	},
	skill_counts_by_sic?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_by_sicKeySpecifier | (() => undefined | skill_counts_by_sicKeySpecifier),
		fields?: skill_counts_by_sicFieldPolicy,
	},
	skill_counts_by_sic_aggregate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_by_sic_aggregateKeySpecifier | (() => undefined | skill_counts_by_sic_aggregateKeySpecifier),
		fields?: skill_counts_by_sic_aggregateFieldPolicy,
	},
	skill_counts_by_sic_aggregate_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_by_sic_aggregate_fieldsKeySpecifier | (() => undefined | skill_counts_by_sic_aggregate_fieldsKeySpecifier),
		fields?: skill_counts_by_sic_aggregate_fieldsFieldPolicy,
	},
	skill_counts_by_sic_avg_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_by_sic_avg_fieldsKeySpecifier | (() => undefined | skill_counts_by_sic_avg_fieldsKeySpecifier),
		fields?: skill_counts_by_sic_avg_fieldsFieldPolicy,
	},
	skill_counts_by_sic_max_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_by_sic_max_fieldsKeySpecifier | (() => undefined | skill_counts_by_sic_max_fieldsKeySpecifier),
		fields?: skill_counts_by_sic_max_fieldsFieldPolicy,
	},
	skill_counts_by_sic_min_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_by_sic_min_fieldsKeySpecifier | (() => undefined | skill_counts_by_sic_min_fieldsKeySpecifier),
		fields?: skill_counts_by_sic_min_fieldsFieldPolicy,
	},
	skill_counts_by_sic_stddev_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_by_sic_stddev_fieldsKeySpecifier | (() => undefined | skill_counts_by_sic_stddev_fieldsKeySpecifier),
		fields?: skill_counts_by_sic_stddev_fieldsFieldPolicy,
	},
	skill_counts_by_sic_stddev_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_by_sic_stddev_pop_fieldsKeySpecifier | (() => undefined | skill_counts_by_sic_stddev_pop_fieldsKeySpecifier),
		fields?: skill_counts_by_sic_stddev_pop_fieldsFieldPolicy,
	},
	skill_counts_by_sic_stddev_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_by_sic_stddev_samp_fieldsKeySpecifier | (() => undefined | skill_counts_by_sic_stddev_samp_fieldsKeySpecifier),
		fields?: skill_counts_by_sic_stddev_samp_fieldsFieldPolicy,
	},
	skill_counts_by_sic_sum_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_by_sic_sum_fieldsKeySpecifier | (() => undefined | skill_counts_by_sic_sum_fieldsKeySpecifier),
		fields?: skill_counts_by_sic_sum_fieldsFieldPolicy,
	},
	skill_counts_by_sic_var_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_by_sic_var_pop_fieldsKeySpecifier | (() => undefined | skill_counts_by_sic_var_pop_fieldsKeySpecifier),
		fields?: skill_counts_by_sic_var_pop_fieldsFieldPolicy,
	},
	skill_counts_by_sic_var_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_by_sic_var_samp_fieldsKeySpecifier | (() => undefined | skill_counts_by_sic_var_samp_fieldsKeySpecifier),
		fields?: skill_counts_by_sic_var_samp_fieldsFieldPolicy,
	},
	skill_counts_by_sic_variance_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_by_sic_variance_fieldsKeySpecifier | (() => undefined | skill_counts_by_sic_variance_fieldsKeySpecifier),
		fields?: skill_counts_by_sic_variance_fieldsFieldPolicy,
	},
	skill_counts_max_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_max_fieldsKeySpecifier | (() => undefined | skill_counts_max_fieldsKeySpecifier),
		fields?: skill_counts_max_fieldsFieldPolicy,
	},
	skill_counts_min_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_min_fieldsKeySpecifier | (() => undefined | skill_counts_min_fieldsKeySpecifier),
		fields?: skill_counts_min_fieldsFieldPolicy,
	},
	skill_counts_stddev_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_stddev_fieldsKeySpecifier | (() => undefined | skill_counts_stddev_fieldsKeySpecifier),
		fields?: skill_counts_stddev_fieldsFieldPolicy,
	},
	skill_counts_stddev_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_stddev_pop_fieldsKeySpecifier | (() => undefined | skill_counts_stddev_pop_fieldsKeySpecifier),
		fields?: skill_counts_stddev_pop_fieldsFieldPolicy,
	},
	skill_counts_stddev_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_stddev_samp_fieldsKeySpecifier | (() => undefined | skill_counts_stddev_samp_fieldsKeySpecifier),
		fields?: skill_counts_stddev_samp_fieldsFieldPolicy,
	},
	skill_counts_sum_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_sum_fieldsKeySpecifier | (() => undefined | skill_counts_sum_fieldsKeySpecifier),
		fields?: skill_counts_sum_fieldsFieldPolicy,
	},
	skill_counts_var_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_var_pop_fieldsKeySpecifier | (() => undefined | skill_counts_var_pop_fieldsKeySpecifier),
		fields?: skill_counts_var_pop_fieldsFieldPolicy,
	},
	skill_counts_var_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_var_samp_fieldsKeySpecifier | (() => undefined | skill_counts_var_samp_fieldsKeySpecifier),
		fields?: skill_counts_var_samp_fieldsFieldPolicy,
	},
	skill_counts_variance_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_counts_variance_fieldsKeySpecifier | (() => undefined | skill_counts_variance_fieldsKeySpecifier),
		fields?: skill_counts_variance_fieldsFieldPolicy,
	},
	skill_pricing_64_651_691?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_64_651_691KeySpecifier | (() => undefined | skill_pricing_64_651_691KeySpecifier),
		fields?: skill_pricing_64_651_691FieldPolicy,
	},
	skill_pricing_64_651_691_aggregate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_64_651_691_aggregateKeySpecifier | (() => undefined | skill_pricing_64_651_691_aggregateKeySpecifier),
		fields?: skill_pricing_64_651_691_aggregateFieldPolicy,
	},
	skill_pricing_64_651_691_aggregate_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_64_651_691_aggregate_fieldsKeySpecifier | (() => undefined | skill_pricing_64_651_691_aggregate_fieldsKeySpecifier),
		fields?: skill_pricing_64_651_691_aggregate_fieldsFieldPolicy,
	},
	skill_pricing_64_651_691_avg_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_64_651_691_avg_fieldsKeySpecifier | (() => undefined | skill_pricing_64_651_691_avg_fieldsKeySpecifier),
		fields?: skill_pricing_64_651_691_avg_fieldsFieldPolicy,
	},
	skill_pricing_64_651_691_max_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_64_651_691_max_fieldsKeySpecifier | (() => undefined | skill_pricing_64_651_691_max_fieldsKeySpecifier),
		fields?: skill_pricing_64_651_691_max_fieldsFieldPolicy,
	},
	skill_pricing_64_651_691_min_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_64_651_691_min_fieldsKeySpecifier | (() => undefined | skill_pricing_64_651_691_min_fieldsKeySpecifier),
		fields?: skill_pricing_64_651_691_min_fieldsFieldPolicy,
	},
	skill_pricing_64_651_691_stddev_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_64_651_691_stddev_fieldsKeySpecifier | (() => undefined | skill_pricing_64_651_691_stddev_fieldsKeySpecifier),
		fields?: skill_pricing_64_651_691_stddev_fieldsFieldPolicy,
	},
	skill_pricing_64_651_691_stddev_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_64_651_691_stddev_pop_fieldsKeySpecifier | (() => undefined | skill_pricing_64_651_691_stddev_pop_fieldsKeySpecifier),
		fields?: skill_pricing_64_651_691_stddev_pop_fieldsFieldPolicy,
	},
	skill_pricing_64_651_691_stddev_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_64_651_691_stddev_samp_fieldsKeySpecifier | (() => undefined | skill_pricing_64_651_691_stddev_samp_fieldsKeySpecifier),
		fields?: skill_pricing_64_651_691_stddev_samp_fieldsFieldPolicy,
	},
	skill_pricing_64_651_691_sum_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_64_651_691_sum_fieldsKeySpecifier | (() => undefined | skill_pricing_64_651_691_sum_fieldsKeySpecifier),
		fields?: skill_pricing_64_651_691_sum_fieldsFieldPolicy,
	},
	skill_pricing_64_651_691_var_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_64_651_691_var_pop_fieldsKeySpecifier | (() => undefined | skill_pricing_64_651_691_var_pop_fieldsKeySpecifier),
		fields?: skill_pricing_64_651_691_var_pop_fieldsFieldPolicy,
	},
	skill_pricing_64_651_691_var_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_64_651_691_var_samp_fieldsKeySpecifier | (() => undefined | skill_pricing_64_651_691_var_samp_fieldsKeySpecifier),
		fields?: skill_pricing_64_651_691_var_samp_fieldsFieldPolicy,
	},
	skill_pricing_64_651_691_variance_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_64_651_691_variance_fieldsKeySpecifier | (() => undefined | skill_pricing_64_651_691_variance_fieldsKeySpecifier),
		fields?: skill_pricing_64_651_691_variance_fieldsFieldPolicy,
	},
	skill_pricing_by_city?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_cityKeySpecifier | (() => undefined | skill_pricing_by_cityKeySpecifier),
		fields?: skill_pricing_by_cityFieldPolicy,
	},
	skill_pricing_by_city_aggregate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_city_aggregateKeySpecifier | (() => undefined | skill_pricing_by_city_aggregateKeySpecifier),
		fields?: skill_pricing_by_city_aggregateFieldPolicy,
	},
	skill_pricing_by_city_aggregate_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_city_aggregate_fieldsKeySpecifier | (() => undefined | skill_pricing_by_city_aggregate_fieldsKeySpecifier),
		fields?: skill_pricing_by_city_aggregate_fieldsFieldPolicy,
	},
	skill_pricing_by_city_avg_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_city_avg_fieldsKeySpecifier | (() => undefined | skill_pricing_by_city_avg_fieldsKeySpecifier),
		fields?: skill_pricing_by_city_avg_fieldsFieldPolicy,
	},
	skill_pricing_by_city_max_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_city_max_fieldsKeySpecifier | (() => undefined | skill_pricing_by_city_max_fieldsKeySpecifier),
		fields?: skill_pricing_by_city_max_fieldsFieldPolicy,
	},
	skill_pricing_by_city_min_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_city_min_fieldsKeySpecifier | (() => undefined | skill_pricing_by_city_min_fieldsKeySpecifier),
		fields?: skill_pricing_by_city_min_fieldsFieldPolicy,
	},
	skill_pricing_by_city_stddev_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_city_stddev_fieldsKeySpecifier | (() => undefined | skill_pricing_by_city_stddev_fieldsKeySpecifier),
		fields?: skill_pricing_by_city_stddev_fieldsFieldPolicy,
	},
	skill_pricing_by_city_stddev_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_city_stddev_pop_fieldsKeySpecifier | (() => undefined | skill_pricing_by_city_stddev_pop_fieldsKeySpecifier),
		fields?: skill_pricing_by_city_stddev_pop_fieldsFieldPolicy,
	},
	skill_pricing_by_city_stddev_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_city_stddev_samp_fieldsKeySpecifier | (() => undefined | skill_pricing_by_city_stddev_samp_fieldsKeySpecifier),
		fields?: skill_pricing_by_city_stddev_samp_fieldsFieldPolicy,
	},
	skill_pricing_by_city_sum_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_city_sum_fieldsKeySpecifier | (() => undefined | skill_pricing_by_city_sum_fieldsKeySpecifier),
		fields?: skill_pricing_by_city_sum_fieldsFieldPolicy,
	},
	skill_pricing_by_city_var_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_city_var_pop_fieldsKeySpecifier | (() => undefined | skill_pricing_by_city_var_pop_fieldsKeySpecifier),
		fields?: skill_pricing_by_city_var_pop_fieldsFieldPolicy,
	},
	skill_pricing_by_city_var_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_city_var_samp_fieldsKeySpecifier | (() => undefined | skill_pricing_by_city_var_samp_fieldsKeySpecifier),
		fields?: skill_pricing_by_city_var_samp_fieldsFieldPolicy,
	},
	skill_pricing_by_city_variance_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_city_variance_fieldsKeySpecifier | (() => undefined | skill_pricing_by_city_variance_fieldsKeySpecifier),
		fields?: skill_pricing_by_city_variance_fieldsFieldPolicy,
	},
	skill_pricing_by_lad?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_ladKeySpecifier | (() => undefined | skill_pricing_by_ladKeySpecifier),
		fields?: skill_pricing_by_ladFieldPolicy,
	},
	skill_pricing_by_lad_aggregate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_lad_aggregateKeySpecifier | (() => undefined | skill_pricing_by_lad_aggregateKeySpecifier),
		fields?: skill_pricing_by_lad_aggregateFieldPolicy,
	},
	skill_pricing_by_lad_aggregate_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_lad_aggregate_fieldsKeySpecifier | (() => undefined | skill_pricing_by_lad_aggregate_fieldsKeySpecifier),
		fields?: skill_pricing_by_lad_aggregate_fieldsFieldPolicy,
	},
	skill_pricing_by_lad_avg_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_lad_avg_fieldsKeySpecifier | (() => undefined | skill_pricing_by_lad_avg_fieldsKeySpecifier),
		fields?: skill_pricing_by_lad_avg_fieldsFieldPolicy,
	},
	skill_pricing_by_lad_max_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_lad_max_fieldsKeySpecifier | (() => undefined | skill_pricing_by_lad_max_fieldsKeySpecifier),
		fields?: skill_pricing_by_lad_max_fieldsFieldPolicy,
	},
	skill_pricing_by_lad_min_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_lad_min_fieldsKeySpecifier | (() => undefined | skill_pricing_by_lad_min_fieldsKeySpecifier),
		fields?: skill_pricing_by_lad_min_fieldsFieldPolicy,
	},
	skill_pricing_by_lad_stddev_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_lad_stddev_fieldsKeySpecifier | (() => undefined | skill_pricing_by_lad_stddev_fieldsKeySpecifier),
		fields?: skill_pricing_by_lad_stddev_fieldsFieldPolicy,
	},
	skill_pricing_by_lad_stddev_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_lad_stddev_pop_fieldsKeySpecifier | (() => undefined | skill_pricing_by_lad_stddev_pop_fieldsKeySpecifier),
		fields?: skill_pricing_by_lad_stddev_pop_fieldsFieldPolicy,
	},
	skill_pricing_by_lad_stddev_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_lad_stddev_samp_fieldsKeySpecifier | (() => undefined | skill_pricing_by_lad_stddev_samp_fieldsKeySpecifier),
		fields?: skill_pricing_by_lad_stddev_samp_fieldsFieldPolicy,
	},
	skill_pricing_by_lad_sum_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_lad_sum_fieldsKeySpecifier | (() => undefined | skill_pricing_by_lad_sum_fieldsKeySpecifier),
		fields?: skill_pricing_by_lad_sum_fieldsFieldPolicy,
	},
	skill_pricing_by_lad_var_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_lad_var_pop_fieldsKeySpecifier | (() => undefined | skill_pricing_by_lad_var_pop_fieldsKeySpecifier),
		fields?: skill_pricing_by_lad_var_pop_fieldsFieldPolicy,
	},
	skill_pricing_by_lad_var_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_lad_var_samp_fieldsKeySpecifier | (() => undefined | skill_pricing_by_lad_var_samp_fieldsKeySpecifier),
		fields?: skill_pricing_by_lad_var_samp_fieldsFieldPolicy,
	},
	skill_pricing_by_lad_variance_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_by_lad_variance_fieldsKeySpecifier | (() => undefined | skill_pricing_by_lad_variance_fieldsKeySpecifier),
		fields?: skill_pricing_by_lad_variance_fieldsFieldPolicy,
	},
	skill_pricing_sic?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_sicKeySpecifier | (() => undefined | skill_pricing_sicKeySpecifier),
		fields?: skill_pricing_sicFieldPolicy,
	},
	skill_pricing_sic_aggregate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_sic_aggregateKeySpecifier | (() => undefined | skill_pricing_sic_aggregateKeySpecifier),
		fields?: skill_pricing_sic_aggregateFieldPolicy,
	},
	skill_pricing_sic_aggregate_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_sic_aggregate_fieldsKeySpecifier | (() => undefined | skill_pricing_sic_aggregate_fieldsKeySpecifier),
		fields?: skill_pricing_sic_aggregate_fieldsFieldPolicy,
	},
	skill_pricing_sic_avg_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_sic_avg_fieldsKeySpecifier | (() => undefined | skill_pricing_sic_avg_fieldsKeySpecifier),
		fields?: skill_pricing_sic_avg_fieldsFieldPolicy,
	},
	skill_pricing_sic_max_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_sic_max_fieldsKeySpecifier | (() => undefined | skill_pricing_sic_max_fieldsKeySpecifier),
		fields?: skill_pricing_sic_max_fieldsFieldPolicy,
	},
	skill_pricing_sic_min_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_sic_min_fieldsKeySpecifier | (() => undefined | skill_pricing_sic_min_fieldsKeySpecifier),
		fields?: skill_pricing_sic_min_fieldsFieldPolicy,
	},
	skill_pricing_sic_stddev_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_sic_stddev_fieldsKeySpecifier | (() => undefined | skill_pricing_sic_stddev_fieldsKeySpecifier),
		fields?: skill_pricing_sic_stddev_fieldsFieldPolicy,
	},
	skill_pricing_sic_stddev_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_sic_stddev_pop_fieldsKeySpecifier | (() => undefined | skill_pricing_sic_stddev_pop_fieldsKeySpecifier),
		fields?: skill_pricing_sic_stddev_pop_fieldsFieldPolicy,
	},
	skill_pricing_sic_stddev_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_sic_stddev_samp_fieldsKeySpecifier | (() => undefined | skill_pricing_sic_stddev_samp_fieldsKeySpecifier),
		fields?: skill_pricing_sic_stddev_samp_fieldsFieldPolicy,
	},
	skill_pricing_sic_sum_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_sic_sum_fieldsKeySpecifier | (() => undefined | skill_pricing_sic_sum_fieldsKeySpecifier),
		fields?: skill_pricing_sic_sum_fieldsFieldPolicy,
	},
	skill_pricing_sic_var_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_sic_var_pop_fieldsKeySpecifier | (() => undefined | skill_pricing_sic_var_pop_fieldsKeySpecifier),
		fields?: skill_pricing_sic_var_pop_fieldsFieldPolicy,
	},
	skill_pricing_sic_var_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_sic_var_samp_fieldsKeySpecifier | (() => undefined | skill_pricing_sic_var_samp_fieldsKeySpecifier),
		fields?: skill_pricing_sic_var_samp_fieldsFieldPolicy,
	},
	skill_pricing_sic_variance_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | skill_pricing_sic_variance_fieldsKeySpecifier | (() => undefined | skill_pricing_sic_variance_fieldsKeySpecifier),
		fields?: skill_pricing_sic_variance_fieldsFieldPolicy,
	},
	subscription_root?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | subscription_rootKeySpecifier | (() => undefined | subscription_rootKeySpecifier),
		fields?: subscription_rootFieldPolicy,
	},
	tiles_salary_residuals_lad?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | tiles_salary_residuals_ladKeySpecifier | (() => undefined | tiles_salary_residuals_ladKeySpecifier),
		fields?: tiles_salary_residuals_ladFieldPolicy,
	},
	tiles_salary_residuals_lad_aggregate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | tiles_salary_residuals_lad_aggregateKeySpecifier | (() => undefined | tiles_salary_residuals_lad_aggregateKeySpecifier),
		fields?: tiles_salary_residuals_lad_aggregateFieldPolicy,
	},
	tiles_salary_residuals_lad_aggregate_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | tiles_salary_residuals_lad_aggregate_fieldsKeySpecifier | (() => undefined | tiles_salary_residuals_lad_aggregate_fieldsKeySpecifier),
		fields?: tiles_salary_residuals_lad_aggregate_fieldsFieldPolicy,
	},
	tiles_salary_residuals_lad_avg_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | tiles_salary_residuals_lad_avg_fieldsKeySpecifier | (() => undefined | tiles_salary_residuals_lad_avg_fieldsKeySpecifier),
		fields?: tiles_salary_residuals_lad_avg_fieldsFieldPolicy,
	},
	tiles_salary_residuals_lad_max_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | tiles_salary_residuals_lad_max_fieldsKeySpecifier | (() => undefined | tiles_salary_residuals_lad_max_fieldsKeySpecifier),
		fields?: tiles_salary_residuals_lad_max_fieldsFieldPolicy,
	},
	tiles_salary_residuals_lad_min_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | tiles_salary_residuals_lad_min_fieldsKeySpecifier | (() => undefined | tiles_salary_residuals_lad_min_fieldsKeySpecifier),
		fields?: tiles_salary_residuals_lad_min_fieldsFieldPolicy,
	},
	tiles_salary_residuals_lad_stddev_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | tiles_salary_residuals_lad_stddev_fieldsKeySpecifier | (() => undefined | tiles_salary_residuals_lad_stddev_fieldsKeySpecifier),
		fields?: tiles_salary_residuals_lad_stddev_fieldsFieldPolicy,
	},
	tiles_salary_residuals_lad_stddev_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | tiles_salary_residuals_lad_stddev_pop_fieldsKeySpecifier | (() => undefined | tiles_salary_residuals_lad_stddev_pop_fieldsKeySpecifier),
		fields?: tiles_salary_residuals_lad_stddev_pop_fieldsFieldPolicy,
	},
	tiles_salary_residuals_lad_stddev_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | tiles_salary_residuals_lad_stddev_samp_fieldsKeySpecifier | (() => undefined | tiles_salary_residuals_lad_stddev_samp_fieldsKeySpecifier),
		fields?: tiles_salary_residuals_lad_stddev_samp_fieldsFieldPolicy,
	},
	tiles_salary_residuals_lad_sum_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | tiles_salary_residuals_lad_sum_fieldsKeySpecifier | (() => undefined | tiles_salary_residuals_lad_sum_fieldsKeySpecifier),
		fields?: tiles_salary_residuals_lad_sum_fieldsFieldPolicy,
	},
	tiles_salary_residuals_lad_var_pop_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | tiles_salary_residuals_lad_var_pop_fieldsKeySpecifier | (() => undefined | tiles_salary_residuals_lad_var_pop_fieldsKeySpecifier),
		fields?: tiles_salary_residuals_lad_var_pop_fieldsFieldPolicy,
	},
	tiles_salary_residuals_lad_var_samp_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | tiles_salary_residuals_lad_var_samp_fieldsKeySpecifier | (() => undefined | tiles_salary_residuals_lad_var_samp_fieldsKeySpecifier),
		fields?: tiles_salary_residuals_lad_var_samp_fieldsFieldPolicy,
	},
	tiles_salary_residuals_lad_variance_fields?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | tiles_salary_residuals_lad_variance_fieldsKeySpecifier | (() => undefined | tiles_salary_residuals_lad_variance_fieldsKeySpecifier),
		fields?: tiles_salary_residuals_lad_variance_fieldsFieldPolicy,
	}
};

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    