import { useState } from 'react'
import { useSpring, animated as a } from 'react-spring'
import { makeStyles, Grid, Button } from '@material-ui/core'
import { atoms } from 'misc'
import { useRecoilState } from 'recoil'
import { LayersOutlined } from '@material-ui/icons'
import LSOAMapIcon from 'assets/images/lsoa_map.jpg'
import TealIcon from 'assets/images/teal.jpg'

import City from 'assets/images/city.png'
import LAD from 'assets/images/lad.png'
import Finance from 'assets/images/finance.jpg'
import Skills from 'assets/images/skills.png'

const useStyles = makeStyles((theme)=>({
  root:{
    position:'absolute',
    right:'1rem',
    top:'1.2rem',
    padding: '1rem 1.5rem',
    paddingTop: '1.2rem',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%), 0px 16px 16px rgb(0 0 0 / 6%)'
  },
  cell:{
    textAlign: 'center',
    position:'relative',
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'column', 
    padding:'0.5rem'
  },
  switch:{
    // backgroundColor: '#ccc',
    margin:'auto'
  }
}))

export default function LayersButton(){
  const [open, setOpen] = useState(false)
  const {maxWidth, maxHeight,opacity} = useSpring({
    maxWidth: open?'20rem':'3rem',
    maxHeight: open?'calc(100vh - 1.75rem)':'calc(0vh - -3rem)',
    opacity: open?1:0
  })

  return(
    <a.div 
      onMouseEnter={()=>{setOpen(true)}}
      onMouseLeave={()=>{setOpen(false)}}
      style={{
        minWidth:'3rem', 
        minHeight:'3rem',
        maxWidth, 
        maxHeight, 
        overflow: 'scroll',
        zIndex:50,
        boxShadow: '0px 4px 4px rgb(0 0 0 / 25%), 0px 16px 16px rgb(0 0 0 / 6%)',
        background: 'white', 
        position:'absolute', 
        top:'1rem', 
        borderRadius:'0.2rem',
        right:'1rem'
      }}>
        {!open&& <Button style={{minWidth:'3rem', minHeight:'3rem', position:'fixed', right:'1rem', top:'1rem'}}>
            <LayersOutlined style={{color: 'black'}}/>
        </Button>}
        <LayersMenu {...{opacity: opacity.to(v=>`${v}`) as unknown as string}}/>
    </a.div>
  )
}


function LayersMenu ({opacity='0'}) {
  const [layer, setLayer] = useRecoilState(atoms.layer);
  const [source, setSource] = useRecoilState(atoms.source);

  const [selected, setSelected] = useRecoilState(atoms.selected);


  return <a.div style={{width:'18rem', padding:'1rem', opacity}}>
    <Toggle 
      header="Layers"
      options={[
        {label:"LADs", value:'lad'},
        {label:'Cities', value:'city'}
      ]}
      src={[LAD, City]}
      setter={setLayer}
      value={layer}
    />
    <Toggle 
      header="Data source"
      options={[
        {label:"Salary residuals", value:'salaries'},
        {label:'Skill pricing', value:'skills'}
      ]}
      src={[Finance, Skills]}
      setter={setSource}
      value={source}
    />
  </a.div>
}

function Toggle({header='', setter=(val:any)=>{}, value='' as any, options=[{label:'a',value:'a' as any},{label:'b',value:'b' as any}], src=['','']}){
  const classes = useStyles()
  const [hover, setHover] = useState<0|1>()

  return(<>
    <h6 style={{
      fontSize:'1rem', 
      lineHeight:'1.2', 
      fontWeight: 500,
      margin: '0.4rem 0 0.4rem 0.5rem',
      color: 'black'
    }}>
      {header}
    </h6>
    <Grid container justifyContent="center" style={{color:'black'}}>
      <Grid item xs={6} className={classes.cell} onPointerEnter={()=>setHover(0)} onPointerLeave={()=>setHover(undefined)} onClick={()=>setter(options[0].value)}>
        <img style={{width:'100%', objectFit:'contain', marginBottom:'0.5rem', borderRadius:'0.4rem', opacity: hover===0?0.8:1, border: value===options[0].value?'solid 4px grey':undefined}} src={src[0]}/>
        {options[0].label}
      </Grid>
      <Grid item xs={6} className={classes.cell} onPointerEnter={()=>setHover(1)} onPointerLeave={()=>setHover(undefined)} onClick={()=>setter(options[1].value)}>
        <img style={{width:'100%', objectFit:'contain', marginBottom:'0.5rem', borderRadius:'0.4rem', opacity: hover===1?0.8:1, border: value===options[1].value?'solid 4px grey':undefined}} src={src[1]}/>
        {options[1].label}
      </Grid>
    </Grid>
  </>)
}